/*eslint-disable @typescript-eslint/no-explicit-any*/
import React, { useCallback, useContext, useEffect, useMemo, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { WarningOutlined } from '@ant-design/icons';
import { createWorkerFactory, useWorker } from '@shopify/react-web-worker';
import { Modal, notification, Spin } from 'antd';
import useSWR, { useSWRInfinite } from 'swr';
import format from 'date-fns/format';
import FileSaver from 'file-saver';
import { Dictionary, debounce, pickBy } from 'lodash';
import Axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import amplitude from 'amplitude-js';
import { SorterResult } from 'antd/lib/table/interface';

import { PermissionContext } from 'context';
import { LanguageContext } from 'context/language';
import { Category } from 'models/Category';
import { ExportCardTransactionsQuery, ExportPayoutHistoryQuery } from 'models/Finance';
import { PaymentCredential, PaymentProvider } from 'models/PaymentKYC';
import Permission from 'models/Permission';
import Post, { PostResponse } from 'models/Post';
import Product from 'models/Product';
import { PricingModel, ProductVariant } from 'models/ProductVariant';
import { errorColor } from 'utils/colors';
import { useAppRegion } from '../hooks/app';
import { isDev, isPH, isStg, isTH, PAYMENT_GATEWAY, PROFILE_ID } from './constants';
import { getGenericErrors } from './error';
import { loadCSV } from './excel';
import { formatProductsToMasterVariantFormat, getDeviceType, pages, sleep } from './functions';
import { userSpaceUrl } from 'shared-components/UserSpaceSelect';
import { fetchProductDetailByIds, fetchNormalProductsByTotal } from 'apis/products';
import Cart from 'models/Cart';
import Order from 'models/Order';
import { Profile } from 'models/Profile';
import { MoreInfoES } from 'models/ESData';
import { DownloadInvoicesContext, DownloadObject } from 'context/invoices';
import { ExportStatus } from 'models/Download';
import { getCategories } from 'apis/category';

const isClient = typeof window !== 'undefined';
const getViewportWidth = () => window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

export const useViewportWidth = (): number => {
  const [width, setWidth] = React.useState(isClient ? getViewportWidth() : 0);

  React.useEffect(() => {
    let timeoutId: NodeJS.Timeout | null = null;
    const resizeListener = () => {
      if (timeoutId) clearTimeout(timeoutId);
      timeoutId = setTimeout(() => setWidth(getViewportWidth()), 150);
    };
    window.addEventListener('resize', resizeListener);

    return () => window.removeEventListener('resize', resizeListener);
  }, []);

  return width;
};

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
};

export const useQuery = (): URLSearchParams => {
  return new URLSearchParams(useLocation().search);
};

export const usePermissions = (): ((input: Permission) => boolean) => {
  const permissions = useContext(PermissionContext);

  const handleCheck = useCallback(
    (input: Permission) => {
      return permissions.includes(input);
    },
    [permissions]
  );

  return handleCheck;
};
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useShowError = () => {
  const { translate } = useContext(LanguageContext);

  const showError = React.useCallback(
    (genericErrors: string[], message: string, description: string) => {
      if (genericErrors.length !== 0) {
        genericErrors.forEach((error) => {
          notification.open({
            message,
            description: translate(error),
            icon: <WarningOutlined style={{ color: errorColor }} />,
          });
        });
      } else {
        notification.open({
          message,
          description,
          icon: <WarningOutlined style={{ color: errorColor }} />,
        });
      }
    },
    [translate]
  );

  return { showError };
};

export const useDownloadInvoices = (
  type: 'carts' | 'orders',
  filter?: MoreInfoES,
  onSuccess?: () => void
): {
  startDownloadInvoices: ({
    cartIds,
    shipmentData,
    isPackingList,
    sortedInfo,
  }: {
    cartIds: string[];
    shipmentData?: { orderId: string; shipmentId?: null | string | React.ReactText; childrentIds?: string[] }[];
    isPackingList?: boolean;
    sortedInfo: SorterResult<Cart | Order>;
  }) => Promise<void>;
  downloadingInvoice: Dictionary<DownloadObject>;
  isDownloading: boolean;
} => {
  const { showError } = useShowError();

  const { translate } = useContext(LanguageContext);
  const { value, setValue } = useContext(DownloadInvoicesContext);

  const [isLoading, setIsLoaidng] = useState(false);

  const startDownloadInvoices = React.useCallback(
    async ({
      cartIds,
      shipmentData = [],
      isPackingList = false,
      sortedInfo,
    }: {
      cartIds: string[];
      shipmentData?: { orderId: string; shipmentId?: null | string | React.ReactText; childrentIds?: string[] }[];
      isPackingList?: boolean;
      sortedInfo: SorterResult<Cart | Order>;
    }) => {
      let tempProcessingId = uuidv4();
      const initData = {
        processingId: tempProcessingId,
        type,
        cartIdsLength: 0,
        onSuccess,
        isPackingList,
      };
      setIsLoaidng(true);
      try {
        const result = await Axios({
          url: `/cart-service/api/${type}/invoices/export`,
          method: 'post',
          data: {
            ids: cartIds,
            ...(!shipmentData.length ? {} : { invoiceOrders: shipmentData }),
            timezone: new Date().getTimezoneOffset() / -60,
            isPackingList,
            orderByClause: {
              orderBy: sortedInfo.field,
              orderDirection: sortedInfo.order === 'ascend' ? 'asc' : 'desc',
            },
            ...filter,
          },
        });

        if (result?.data?.id) {
          tempProcessingId = result?.data?.id;
          initData.processingId = tempProcessingId;
          initData.cartIdsLength = cartIds.length || 0;
          setValue({ ...value, [tempProcessingId]: initData });
        }
      } catch (err) {
        const genericErrors = getGenericErrors(err);
        showError(genericErrors, translate('download_invoices_error'), translate('error_occurred_try_again'));
      } finally {
        setIsLoaidng(false);
      }
    },
    [type, onSuccess, filter, showError, translate, setValue, value]
  );

  return {
    startDownloadInvoices,
    downloadingInvoice: pickBy(value, (item) => item.type === type),
    isDownloading: isLoading,
  };
};

export const useDownloadTransactionHistory = (): {
  startDownloadHistory: (transactionIds: string[]) => void;
  loading: boolean;
  Component?: React.ReactElement;
} => {
  const { showError } = useShowError();
  const { translate } = useContext(LanguageContext);
  const { analyticsTrack } = useSegement();
  const [loading, setLoading] = useState(false);
  const [processingId, setProcessingId] = useState();
  const [fileUrl, setFileUrl] = useState<string>();
  const transactionIdsRef = useRef<any>();

  const startDownloadHistory = React.useCallback(
    async (transactionIds: string[]) => {
      setLoading(true);
      setFileUrl(undefined);
      try {
        transactionIdsRef.current = transactionIds;
        const result = await Axios({
          url: `/payment-service/api/balance-transactions/export`,
          method: 'post',
          data: { ids: transactionIds, timezone: new Date().getTimezoneOffset() / -60 },
        });

        if (result?.data?.id) {
          setProcessingId(result?.data?.id);
        } else {
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
        const genericErrors = getGenericErrors(err);
        showError(genericErrors, translate('download_account_history_error'), translate('error_occurred_try_again'));
      }
    },
    [showError, translate]
  );
  const { data } = useSWR<{
    exportStatus: number;
    fileKey?: string;
    fileUrl?: string;
    isDeleted?: boolean;
    serviceName?: string;
  }>(processingId && !fileUrl ? `/payment-service/api/balance-transactions/export/${processingId}` : null, {
    refreshInterval: 2000,
  });

  useEffect(() => {
    if (data?.fileUrl) {
      setFileUrl(data.fileUrl);
      setLoading(false);
    }
  }, [data]);

  useEffect(() => {
    if (data?.exportStatus === -2) {
      // error
      setLoading(false);
      setProcessingId(undefined);
      setFileUrl(undefined);
      showError([], translate('download_account_history_error'), translate('error_occurred_try_again'));
    }
  }, [data, showError, translate]);

  let Component;

  if (loading || fileUrl) {
    Component = (
      <Modal
        title={translate('download_account_history')}
        visible={!!(loading || fileUrl)}
        cancelButtonProps={{
          style: {
            display: 'none',
          },
        }}
        confirmLoading={loading}
        okText={translate('download')}
        onOk={async () => {
          if (fileUrl) {
            analyticsTrack('Exported File', {
              type: 'payout report',
              num_selected: transactionIdsRef.current.length || 0,
              file_type: 'csv',
            });
            const res = await fetch(fileUrl).then((r) => r.blob());
            const fileNames = fileUrl.split('?X-Amz-Expires')[0].split('/'); // get file name
            FileSaver.saveAs(new Blob([res]), fileNames[fileNames.length - 1]);
          }
        }}
        onCancel={() => {
          if (fileUrl) {
            setLoading(false);
            setFileUrl(undefined);
            setProcessingId(undefined);
          }
        }}
      >
        <div className="flex flex-col items-center justify-center">
          {loading ? (
            <>
              <p>{translate('processing')}</p>
              <p>{translate('please_wait_and_do_not_close_the_browser')}</p>
              <Spin className="mt-2" />
            </>
          ) : (
            <p>{translate('please_press_download_to_generate_report')}</p>
          )}
        </div>
      </Modal>
    );
  }

  return {
    startDownloadHistory,
    loading,
    Component,
  };
};

export const useExportCardTransaction = (): {
  startExportCardTransaction: (transactionIds: string[]) => void;
  loading: boolean;
  Component?: React.ReactElement;
} => {
  const { showError } = useShowError();
  const { translate } = useContext(LanguageContext);
  const { analyticsTrack } = useSegement();
  const [loading, setLoading] = useState(false);
  const [processingId, setProcessingId] = useState();
  const [fileUrl, setFileUrl] = useState<string>();
  const transactionIdsRef = useRef<any>();

  const startExportCardTransaction = React.useCallback(
    async (transactionIds: string[]) => {
      setLoading(true);
      setFileUrl(undefined);
      try {
        transactionIdsRef.current = transactionIds;
        const result = await Axios({
          url: `/payment-service/api/transactions/export`,
          method: 'post',
          data: { ids: transactionIds, timezone: new Date().getTimezoneOffset() / -60 },
        });

        if (result?.data?.id) {
          setProcessingId(result?.data?.id);
        } else {
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
        const genericErrors = getGenericErrors(err);
        showError(genericErrors, translate('export_card_transactions_error'), translate('error_occurred_try_again'));
      }
    },
    [showError, translate]
  );
  const { data } = useSWR<{
    exportStatus: number;
    fileKey?: string;
    fileUrl?: string;
    isDeleted?: boolean;
    serviceName?: string;
  }>(processingId && !fileUrl ? `/payment-service/api/transactions/export/${processingId}` : null, {
    refreshInterval: 2000,
  });

  useEffect(() => {
    if (data?.fileUrl) {
      setFileUrl(data.fileUrl);
      setLoading(false);
    }
  }, [data]);

  useEffect(() => {
    if (data?.exportStatus === -2) {
      // error
      setLoading(false);
      setProcessingId(undefined);
      setFileUrl(undefined);
      showError([], translate('export_card_transactions_error'), translate('error_occurred_try_again'));
    }
  }, [data, showError, translate]);

  let Component;

  if (loading || fileUrl) {
    Component = (
      <Modal
        title={translate('export_card_transactions')}
        visible={!!(loading || fileUrl)}
        cancelButtonProps={{
          style: {
            display: 'none',
          },
        }}
        confirmLoading={loading}
        okText={translate('download')}
        onOk={async () => {
          if (fileUrl) {
            analyticsTrack('Exported File', {
              type: 'card transactions',
              num_selected: transactionIdsRef.current?.length || 0,
              file_type: 'csv',
            });
            window.location.href = fileUrl;
          }
        }}
        onCancel={() => {
          if (fileUrl) {
            setLoading(false);
            setFileUrl(undefined);
            setProcessingId(undefined);
          }
        }}
      >
        <div className="flex flex-col items-center justify-center">
          {loading ? (
            <>
              <p>{translate('processing')}</p>
              <p>{translate('please_wait_and_do_not_close_the_browser')}</p>
              <Spin className="mt-2" />
            </>
          ) : (
            <p>{translate('please_press_download_to_generate_report')}</p>
          )}
        </div>
      </Modal>
    );
  }

  return {
    startExportCardTransaction,
    loading,
    Component,
  };
};

export const useAbTestingFeatures = (
  isLoggedIn?: boolean
): {
  hasFeature: (feature: string) => boolean | null;
  isLoading?: boolean;
} => {
  const enabled = isLoggedIn !== undefined ? isLoggedIn : true;
  const result = useSWR<
    {
      created?: string;
      featureName: string;
      id: string;
      merchantId: string;
    }[]
  >(enabled ? '/profile-service/api/profile/feature' : null);
  const listFeatures = result.data?.map?.((o) => o.featureName.toLowerCase()) || [];

  const hasFeature = (feature: string) => {
    if (feature === PAYMENT_GATEWAY) return true;
    if (!result.data || !listFeatures) {
      return null;
    }

    return listFeatures.includes(feature.toLowerCase());
  };

  return {
    isLoading: !result.data,
    hasFeature,
  };
};

export const usePaymentGateway2C2PFeature = () => {
  const { data } = useSWR<boolean>('/profile-service/api/profile/feature/paymentgateway2c2p');

  return data;
};

export const useCheckMerchantAcquirer = () => {
  const hasPaymentGateway2c2p = usePaymentGateway2C2PFeature();

  return isPH || (isTH && hasPaymentGateway2c2p);
};

export const useGenerateCheckoutLink = (): string => {
  return process.env.REACT_APP_CHECKOUT_BASE_URL || '';
};

export const useFetchPosts = (
  keyQuery: (pageIndex: number, previousPageData: PostResponse | null) => string | null,
  limit: number
): {
  posts: Post[] | [];
  isLoadingMore: boolean;
  isReachingEnd: boolean;
  isValidating: boolean;
  isLoadingInitialData: boolean;
  size: number | undefined;
  setSize: ((size: number | ((size: number) => number)) => Promise<PostResponse[] | undefined>) | undefined;
  revalidate: () => void;
  total: number;
} => {
  const { data, size, setSize, revalidate, isValidating, error } = useSWRInfinite<PostResponse>(keyQuery, {
    revalidateOnFocus: false,
    revalidateAll: true,
  });

  const posts = React.useMemo(() => {
    return (
      data?.reduce<Post[]>((res, cur) => {
        // eslint-disable-next-line no-unsafe-optional-chaining
        return [...res, ...cur?.data];
      }, []) || []
    );
  }, [data]);
  const isLoadingInitialData = !data && !error;
  const isLoadingMore = !!(isLoadingInitialData || (size && size > 0 && data && typeof data[size - 1] === 'undefined'));
  const isEmpty = !data?.[0]?.data.length;
  const isReachingEnd = !!(isEmpty || (data && data[data.length - 1]?.data.length < limit));
  const total = React.useMemo(() => data?.[0]?.total || 0, [data]);

  return {
    posts,
    isLoadingMore,
    isReachingEnd,
    isValidating,
    isLoadingInitialData,
    size,
    setSize,
    revalidate,
    total,
  };
};

const FIRST_TIME_DISPLAY_STREAMING_APP_BANNER_KEY = 'streaming_app_banner';

export const useDisplayStreamingAppBanner = (): {
  isFirstTimeDisplay: boolean;
  visible: boolean;
  setIsFirstTimeDisplay: (isFirstTimeDisplay: boolean) => void;
  openBannerModal: () => void;
  closeBannerModal: () => void;
} => {
  const [isFirstTimeDisplay, setIsFirstTimeDisplay] = useState(false);
  const [visible, setVisible] = useState(false);

  const fetchFirstTimeInfo = async () => {
    try {
      const isFirstTime = !window.localStorage.getItem(FIRST_TIME_DISPLAY_STREAMING_APP_BANNER_KEY);
      setIsFirstTimeDisplay(isFirstTime);
    } catch (error) {
      setIsFirstTimeDisplay(true);
    }
  };

  const openBannerModal = useCallback(() => {
    setVisible(true);
  }, [setVisible]);

  const closeBannerModal = useCallback(() => {
    setVisible(false);
    setIsFirstTimeDisplay(false);
    window.localStorage.setItem(FIRST_TIME_DISPLAY_STREAMING_APP_BANNER_KEY, 'false');
  }, [setVisible, setIsFirstTimeDisplay]);

  useEffect(() => {
    fetchFirstTimeInfo();
  }, [setIsFirstTimeDisplay]);

  useEffect(() => {
    if (isFirstTimeDisplay) {
      setVisible(true);
    }
  }, [isFirstTimeDisplay, setVisible]);

  return { isFirstTimeDisplay, setIsFirstTimeDisplay, visible, openBannerModal, closeBannerModal };
};

const createWorker = createWorkerFactory(() => import('./xlsx-worker'));

export const useExportExcel = (
  type: 'carts' | 'shipments'
): {
  startExportExcel: (data: any, eventTrackingData?: any) => Promise<void>;
  loading: boolean;
  Component?: React.ReactElement;
} => {
  const { showError } = useShowError();
  const { analyticsTrack } = useSegement();

  const { translate } = useContext(LanguageContext);
  const [loading, setLoading] = useState(false);
  const [outputFile, setOutputFile] = useState<any>();
  const [fileName, setFileName] = useState<string>('');
  const worker = useWorker(createWorker);
  const dataRef = useRef();
  const eventTrackingDataRef = useRef<any>();
  const currentProgressId = useRef();
  const { region } = useAppRegion();

  useEffect(() => {
    return () => {
      if (currentProgressId?.current) {
        Axios({
          url: `/cart-service/api/${type}/invoices/cancellation/${currentProgressId.current}`,
          method: 'PUT',
        });
        currentProgressId.current = undefined;
      }
    };
  }, [type]);

  const startExportExcel = React.useCallback(
    async (data: any, eventTrackingData?: any) => {
      setLoading(true);
      setFileName('');
      setOutputFile(undefined);
      try {
        const getProgressRes = await Axios({
          url: `/cart-service/api/${type}/csv/export`,
          method: 'post',
          data,
        });
        const progressId = getProgressRes.data.id;
        currentProgressId.current = progressId;
        let done = false;
        while (!done) {
          const progressRes = await Axios({
            url: `/cart-service/api/${type}/csv/export/${progressId}`,
            headers: {},
          });
          dataRef.current = data;
          eventTrackingDataRef.current = eventTrackingData;
          const status = progressRes.data?.exportStatus;
          if (status !== ExportStatus.Processing) {
            if (status === ExportStatus.Completed) {
              const result = await fetch(progressRes.data.fileUrl);
              let resultBody = await result.text();
              resultBody = resultBody?.replaceAll('Waiting For Payment', 'Pending Checkout'); // map all Waiting for Payment into Pending Checkout
              const fileName = progressRes.data?.fileKey.split('/').pop().split('.')[0] + '.xlsx';
              setFileName(fileName);

              const file = new File([resultBody], fileName);
              const data = await loadCSV(file);
              const output =
                type === 'carts'
                  ? await worker.exportDataForOrder(data, region)
                  : await worker.exportDataForShipment(data, region);
              setOutputFile(output);
            }
            if (status === ExportStatus.Failed) {
              showError([], translate('export_csv_error'), translate('error_occurred_try_again'));
            }

            currentProgressId.current = undefined;
            done = true;
          }
          await sleep(2000);
        }
      } catch (err: any) {
        // eslint-disable-next-line no-console
        console.error(err);
        const genericErrors = getGenericErrors(err);
        showError(
          genericErrors,
          translate('export_excel_error'),
          err?.toString?.() || translate('error_occurred_try_again')
        );
      } finally {
        setLoading(false);
      }
    },
    [type, worker, region, showError, translate]
  );

  let Component;

  if (loading || outputFile) {
    Component = (
      <Modal
        title={translate('export_excel')}
        open={!!(loading || outputFile)}
        cancelButtonProps={{
          style: {
            display: 'none',
          },
        }}
        confirmLoading={loading}
        okText={translate('download')}
        onOk={async () => {
          if (outputFile) {
            outputFile.xlsx.writeBuffer().then(function (buffer: BlobPart) {
              const blob = new Blob([buffer], { type: 'application/xlsx' });
              FileSaver.saveAs(blob, fileName);
            });
            analyticsTrack('Exported File', {
              type: type === 'carts' ? 'pending orders' : 'Shipment Export',
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              num_selected: eventTrackingDataRef.current?.numSelected || 0,
              export_type: eventTrackingDataRef.current?.exportType || '',
              file_type: 'excel',
              ...(eventTrackingDataRef.current?.tab ? { tab: eventTrackingDataRef.current?.tab } : {}),
            });
          }
        }}
        onCancel={() => {
          if (outputFile) {
            setFileName('');
            setLoading(false);
            setOutputFile(undefined);
          }
        }}
      >
        <div className="flex flex-col items-center justify-center">
          {loading ? (
            <>
              <p>{translate('processing')}</p>
              <p>{translate('please_wait_and_do_not_close_the_browser')}</p>
              <Spin className="mt-2" />
            </>
          ) : (
            <p>{translate('please_press_download_to_generate_report')}</p>
          )}
        </div>
      </Modal>
    );
  }

  return {
    startExportExcel,
    loading,
    Component,
  };
};

let amplitudeInited = false;

export const useSegement = (): {
  analyticsPage: (pageName: string) => void;
  analyticsTrack: (eventName: string, payload?: any, customPageKey?: string) => void;
  analyticsIdentify: (userId?: string, userInfo?: any) => void;
} => {
  useEffect(() => {
    if (!amplitudeInited && !!process.env.REACT_APP_AMPLITUDE_KEY) {
      amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE_KEY as string);
      amplitudeInited = true;
    }
  }, []);

  const analyticsPage = (pageName: string) => {
    return amplitude.getInstance()?.logEvent?.('Page View', { pageName, device_type: getDeviceType() });
  };

  const analyticsTrack = useCallback((eventName: string, payload?: any, customPageKey?: string) => {
    const profileId = localStorage.getItem(PROFILE_ID);
    const build = process.env.REACT_APP_REGION;
    const url = new URL(window.location.href);
    const pageKeyName = url.pathname.split('/').filter((o) => !!o)?.[0] || '';

    if (isDev || isStg) {
      // eslint-disable-next-line no-console
      console.log(`Seller-${eventName}`, {
        user_id: profileId,
        platform: 'desktop',
        build: build,
        page_screen: pages[`/${customPageKey ?? pageKeyName}`],
        page_link: url.href,
        ...payload,
      });
    }
    return amplitude.getInstance()?.logEvent?.(`Seller-${eventName}`, {
      category: 'Dashboard',
      user_id: profileId,
      platform: 'desktop',
      build: build,
      page_screen: pages[`/${pageKeyName}`],
      page_link: url.href,
      ...payload,
    });
  }, []);

  const analyticsIdentify = (userId?: string, userInfo?: any) => {
    amplitude.getInstance().setUserId(userId || null);
    amplitude.getInstance().setUserProperties(userInfo);
  };

  return { analyticsPage, analyticsTrack, analyticsIdentify };
};

const createExportProductsWorker = createWorkerFactory(() => import('./workers/products-worker'));

/* A hook that is used to export products to a CSV file. */
export const useExportProducts = (): {
  startExportProducts: (
    params: { list?: (Product | ProductVariant)[]; search?: string; hasProductCosePermission?: boolean },
    eventTrackingData?: any
  ) => Promise<void>;
  loading: boolean;
  Component?: React.ReactElement;
} => {
  const { showError } = useShowError();
  const { translate } = useContext(LanguageContext);
  const [loading, setLoading] = useState(false);
  const [outputContent, setOutputContent] = useState<string>();
  const worker = useWorker(createExportProductsWorker);
  const { analyticsTrack } = useSegement();
  const dataRef = useRef();
  const eventTrackingDataRef = useRef<any>();

  const startExportProducts = React.useCallback(
    async (params: { list?: (Product | ProductVariant)[]; search?: string }, eventTrackingData?: any) => {
      setLoading(true);
      setOutputContent(undefined);
      try {
        const output = await worker.exportProductsCsv(params, translate);
        setOutputContent(output);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        dataRef.current = params;
        eventTrackingDataRef.current = eventTrackingData;
      } catch (err) {
        const genericErrors = getGenericErrors(err);
        showError(genericErrors, translate('export_csv_error'), translate('error_occurred_try_again'));
      } finally {
        setLoading(false);
      }
    },
    [showError, translate, worker]
  );

  const Component = useMemo(() => {
    if (loading || outputContent) {
      return (
        <Modal
          title={translate('export_csv')}
          visible={!!(loading || outputContent)}
          cancelButtonProps={{
            style: {
              display: 'none',
            },
          }}
          confirmLoading={loading}
          okText={translate('download')}
          onOk={async () => {
            if (outputContent) {
              const blob = new Blob([outputContent], { type: 'text/csv;charset=utf-8;' });
              const fileName = `list_products_${format(new Date(), 'dd/MM/yyyy_HH:mm')}`;

              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              if (navigator?.msSaveBlob) {
                // In case of IE 10+
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                navigator?.msSaveBlob?.(blob, fileName);
              } else {
                const link = document.createElement('a');
                if (link.download !== undefined) {
                  // Browsers that support HTML5 download attribute
                  const url = URL.createObjectURL(blob);
                  link.setAttribute('href', url);
                  link.setAttribute('download', fileName);
                  link.style.visibility = 'hidden';
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                }
              }
              analyticsTrack('Exported File', {
                type: 'export product list',
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                num_selected: eventTrackingDataRef.current?.numSelected || 0,
                file_type: 'CSV',
              });
            }
          }}
          onCancel={() => {
            if (outputContent) {
              setLoading(false);
              setOutputContent(undefined);
            }
          }}
        >
          <div className="flex flex-col items-center justify-center">
            {loading ? (
              <>
                <p>{translate('processing')}</p>
                <p>{translate('please_wait_and_do_not_close_the_browser')}</p>
                <Spin className="mt-2" />
              </>
            ) : (
              <p>{translate('please_press_download_to_generate_report')}</p>
            )}
          </div>
        </Modal>
      );
    }
  }, [analyticsTrack, loading, outputContent, translate]);

  return {
    startExportProducts,
    loading,
    Component,
  };
};

const createExportsCardTransactionsWorker = createWorkerFactory(() => import('./workers/cards-worker'));
/* A React hook that is used to export ALL card transactions (by FE). */
export const useExportAllCardTransactions = (): {
  startExport: (params: ExportCardTransactionsQuery, totalNum?: number) => Promise<void>;
  loading: boolean;
  Component?: React.ReactElement;
} => {
  const { showError } = useShowError();
  const { analyticsTrack } = useSegement();
  const { translate } = useContext(LanguageContext);
  const [loading, setLoading] = useState(false);
  const [outputContent, setOutputContent] = useState<string>();
  const worker = useWorker(createExportsCardTransactionsWorker);
  const totalNumRef = useRef<any>();

  const startExport = React.useCallback(
    async (params: ExportCardTransactionsQuery, totalNum?: number) => {
      setLoading(true);
      setOutputContent(undefined);
      totalNumRef.current = totalNum;
      try {
        const output = await worker.exportCardTransactions(params, translate);
        setOutputContent(output);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        const genericErrors = getGenericErrors(err);
        showError(genericErrors, translate('export_csv_error'), translate('error_occurred_try_again'));
      }
    },
    [showError, translate, worker]
  );

  let Component;

  if (loading || outputContent) {
    Component = (
      <Modal
        title={translate('export_csv')}
        open={!!(loading || outputContent)}
        cancelButtonProps={{
          style: {
            display: 'none',
          },
        }}
        confirmLoading={loading}
        okText={translate('download')}
        onOk={async () => {
          if (outputContent) {
            analyticsTrack('Exported File', {
              type: 'card transactions',
              file_type: 'csv',
              num_selected: totalNumRef.current || null,
            });
            const blob = new Blob([outputContent], { type: 'text/csv;charset=utf-8;' });
            const fileName = `list_card_transactions_${format(new Date(), 'dd/MM/yyyy_HH:mm')}`;

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (navigator?.msSaveBlob) {
              // In case of IE 10+
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              navigator?.msSaveBlob?.(blob, fileName);
            } else {
              const link = document.createElement('a');
              if (link.download !== undefined) {
                // Browsers that support HTML5 download attribute
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', fileName);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              }
            }
          }
        }}
        onCancel={() => {
          if (outputContent) {
            setLoading(false);
            setOutputContent(undefined);
          }
        }}
      >
        <div className="flex flex-col items-center justify-center">
          {loading ? (
            <>
              <p>{translate('processing')}</p>
              <p>{translate('please_wait_and_do_not_close_the_browser')}</p>
              <Spin className="mt-2" />
            </>
          ) : (
            <p>{translate('please_press_download_to_generate_report')}</p>
          )}
        </div>
      </Modal>
    );
  }

  return {
    startExport,
    loading,
    Component,
  };
};

export const useGodMode = () => {
  const { data, ...rest } = useSWR(userSpaceUrl);

  const userSpace = useMemo(() => (data && data?.myspace?.isBusinessActived && data.userspace) || [], [data]);

  return { data: userSpace?.length > 0, ...rest };
};

export const usePaymentCredentials = () => {
  return useSWR<PaymentCredential[]>('/profile-service/api/profile/payment-credentials');
};

const exportPayoutWorker = createWorkerFactory(() => import('./workers/payout-worker'));
export const useExportPayout = (): {
  startExport: (params: ExportPayoutHistoryQuery, fileName: string) => Promise<void>;
  loading: boolean;
  Component?: React.ReactElement;
} => {
  const { showError } = useShowError();
  const { analyticsTrack } = useSegement();

  const { translate } = useContext(LanguageContext);
  const [loading, setLoading] = useState(false);
  const [outputFile, setOutputFile] = useState<any>();
  const [fileName, setFileName] = useState<string>('');
  const worker = useWorker(exportPayoutWorker);
  const payoutsRef = useRef<any>();

  const startExport = React.useCallback(
    async (params: ExportPayoutHistoryQuery, fileName: string) => {
      setLoading(true);
      setFileName('');
      setOutputFile(undefined);
      try {
        const payouts = await worker.getPayoutCSV(params, translate);

        if (payouts) {
          payoutsRef.current = payouts;
          setFileName(fileName || `Settlement report-${format(new Date(), 'dd/MM/yyyy_HH:mm')}.xlsx`);
          const output = await worker.exportData(payouts);
          setOutputFile(output);

          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
        const genericErrors = getGenericErrors(err);
        showError(genericErrors, translate('export_excel_error'), translate('error_occurred_try_again'));
      }
    },
    [showError, translate, worker]
  );

  let Component;

  if (loading || outputFile) {
    Component = (
      <Modal
        title={translate('export_payout_report')}
        visible={!!(loading || outputFile)}
        cancelButtonProps={{
          style: {
            display: 'none',
          },
        }}
        confirmLoading={loading}
        okText={translate('download')}
        onOk={() => {
          if (outputFile) {
            analyticsTrack('Exported File', {
              type: 'payout report',
              num_selected: payoutsRef.current?.filter((o: any) => !!o[0])?.length - 1,
              file_type: 'excel',
            });
            outputFile.xlsx.writeBuffer().then(function (buffer: BlobPart) {
              const blob = new Blob([buffer], { type: 'application/xlsx' });
              FileSaver.saveAs(blob, fileName);
            });
          }
        }}
        onCancel={() => {
          if (outputFile) {
            setFileName('');
            setLoading(false);
            setOutputFile(undefined);
          }
        }}
      >
        <div className="flex flex-col items-center justify-center">
          {loading ? (
            <>
              <p>{translate('processing')}</p>
              <p>{translate('please_wait_and_do_not_close_the_browser')}</p>
              <Spin className="mt-2" />
            </>
          ) : (
            <p>{translate('please_press_download_to_generate_report')}</p>
          )}
        </div>
      </Modal>
    );
  }

  return {
    startExport,
    loading,
    Component,
  };
};

export const usePaymentCredential = (provider: PaymentProvider) => {
  const { data, ...rest } = usePaymentCredentials();
  const credential = useMemo(() => data?.find((p) => p.paymentProvider === provider), [data, provider]);

  return { data: credential, ...rest };
};

const downloadTemplateWorker = createWorkerFactory(() => import('./workers/template-worker'));
export const useDownloadTemplateCreate = (): {
  startExport: (categories: Category[], isOthersCategory?: boolean) => Promise<void>;
  loading: boolean;
} => {
  const { showError } = useShowError();
  const { translate } = useContext(LanguageContext);
  const [loading, setLoading] = useState(false);
  const worker = useWorker(downloadTemplateWorker);

  const startExport = React.useCallback(
    async (categories: Category[], isOthersCategory?: boolean) => {
      setLoading(true);
      try {
        const content = isOthersCategory
          ? await worker.downloadCreateQuickTemplate(categories, translate)
          : await worker.downloadCreateTemplate(categories, translate);
        const fileName = `template-upload.xlsx`;
        content.xlsx.writeBuffer().then(function (buffer: BlobPart) {
          const blob = new Blob([buffer], { type: 'application/xlsx' });
          FileSaver.saveAs(blob, fileName);
        });
      } catch (err) {
        const genericErrors = getGenericErrors(err);
        showError(genericErrors, translate('download_template_error'), translate('error_occurred_try_again'));
      } finally {
        setLoading(false);
      }
    },
    [showError, translate, worker]
  );

  return {
    startExport,
    loading,
  };
};

export const useDownloadTemplateUpdate = (): {
  startExport: ({ productIds }: { productIds: string[] }) => Promise<void>;
  loading: boolean;
} => {
  const { showError } = useShowError();
  const { translate } = useContext(LanguageContext);
  const [loading, setLoading] = useState(false);
  const worker = useWorker(downloadTemplateWorker);

  const checkPermission = usePermissions();
  const hasProductCosePermission = checkPermission('ProductCostFeature');

  const startExport = React.useCallback(
    async ({ productIds }: { productIds: string[] }) => {
      setLoading(true);
      try {
        const { categories, formattedCategoriesList } = await getCategories();
        let products = await fetchProductDetailByIds(productIds);
        // ignore FillUp Products has Commission model
        products = products.filter((o) => {
          return o.pricingModel !== PricingModel.Commission;
        });
        const content = await worker.downloadUpdateTemplate({
          localize: translate,
          categories,
          categoriesDictionary: formattedCategoriesList,
          products: formatProductsToMasterVariantFormat(products),
          hasProductCosePermission,
        });

        const fileName = `product-update-template.xlsx`;
        const buffer: BlobPart = await content.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/xlsx' });
        FileSaver.saveAs(blob, fileName);
      } catch (err) {
        const genericErrors = getGenericErrors(err);
        showError(genericErrors, translate('download_template_error'), translate('error_occurred_try_again'));
      } finally {
        setLoading(false);
      }
    },
    [hasProductCosePermission, showError, translate, worker]
  );

  return {
    startExport,
    loading,
  };
};

const downloadUpdateVersionProductTemplateWorker = createWorkerFactory(() => import('./workers/update-product-worker'));
export const useDownloadUpdateVersionProductTemplate = (): {
  startExport: ({
    localize,
    categories,
    products,
    totalProducts,
  }: {
    localize: (raw: string) => string;
    categories: Category[];
    products?: Product[];
    totalProducts?: number;
  }) => Promise<void>;
  loading: boolean;
} => {
  const { showError } = useShowError();
  const { translate } = useContext(LanguageContext);
  const [loading, setLoading] = useState(false);
  const worker = useWorker(downloadUpdateVersionProductTemplateWorker);

  const startExport = React.useCallback(
    async ({
      localize,
      categories,
      products,
      totalProducts = 0,
    }: {
      localize: (raw: string) => string;
      categories: Category[];
      products?: Product[];
      totalProducts?: number;
    }) => {
      setLoading(true);
      try {
        let productsToDownload: Product[];
        if (totalProducts) {
          productsToDownload = await fetchNormalProductsByTotal(totalProducts);
        } else productsToDownload = [...(products || [])];
        const content = await worker.downloadUpdateVersionProductTemplate(localize, categories, productsToDownload);
        const fileName = `update-product-version.xlsx`;
        const buffer: BlobPart = await content.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/xlsx' });
        FileSaver.saveAs(blob, fileName);
      } catch (err) {
        const genericErrors = getGenericErrors(err);
        showError(genericErrors, translate('download_template_error'), translate('error_occurred_try_again'));
      } finally {
        setLoading(false);
      }
    },
    [showError, translate, worker]
  );

  return {
    startExport,
    loading,
  };
};

export const useOutsideClicker = (ref: React.RefObject<HTMLDivElement>, onClickOutside: () => void) => {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref && ref.current && !ref.current.contains(event.target as Node)) {
        onClickOutside();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [ref, onClickOutside]);
};

export const useClearUrlStateOnReload = () => {
  useEffect(() => {
    const unload = () => {
      const newUrl = window.location.href.split('?')[0];
      history.replaceState(null, '', newUrl);
    };
    window.addEventListener('beforeunload', unload);
    return () => {
      window.removeEventListener('beforeunload', unload);
    };
  }, []);
};

export const useLocalStorage = (key: string): [string | null, (newValue: string) => void] => {
  const [value, setValue] = useState<string | null>(localStorage.getItem(key));

  useEffect(() => {
    const interval = setInterval(() => {
      const newValue = localStorage.getItem(key);
      if (newValue !== value) {
        setValue(newValue);
      }
    }, 1000); // Adjust the polling interval as needed

    return () => {
      clearInterval(interval);
    };
  }, [key, value]);

  const handleSetValue = (newValue: string) => {
    localStorage.setItem(key, newValue);
  };

  return [value, handleSetValue];
};

export const useAddHelppierScripts = (profile?: Profile) => {
  useEffect(() => {
    const helppierKey = process.env.REACT_APP_HELPPIER_KEY;
    const helppierID = process.env.REACT_APP_HELPPIER_ID;
    const userId = localStorage.getItem(PROFILE_ID);
    const script = document.createElement('script');

    if (helppierKey && helppierID && userId && profile) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.helppierSegmentation = { build: process.env.REACT_APP_REGION };
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.help_company_key = helppierKey;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.helppier_app_id = helppierID;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.helppierIdentify = {
        userId, // merchant's workspace
      };

      script.setAttribute('id', 'helppierEmbed');
      script.setAttribute('defer', '');
      script.setAttribute('src', 'https://km.helppier.com/widget/js/start.js?help_company_key=' + helppierKey);
      document?.body?.appendChild(script);
      // eslint-disable-next-line no-console
      console.log('Installed Helppier!', userId);
    }

    return () => {
      if (helppierKey && helppierID && userId && profile) {
        // eslint-disable-next-line no-console
        console.log('Removed Helppier!');
        document?.body?.removeChild(script);
      }
    };
  }, [profile]);
};

export const useSearch = () => {
  const { analyticsTrack } = useSegement();

  const [search, setSearch] = React.useState('');
  const [rawSearch, setRawSearch] = React.useState<string>('');

  const handleDebounceSearch = React.useMemo(
    () =>
      debounce((value: string) => {
        setSearch(value);
        analyticsTrack('Searched Keywords', {
          length_char: value?.length || 0,
          keywords: value,
        });
      }, 250),
    [analyticsTrack]
  );

  const handleSearch = React.useCallback(
    (val: React.ChangeEvent<HTMLInputElement>) => {
      handleDebounceSearch(val.target.value?.toLocaleLowerCase());
      setRawSearch(val.target.value?.toLocaleLowerCase());
    },
    [handleDebounceSearch]
  );

  return {
    handleSearch,
    search,
    rawSearch,
  };
};
