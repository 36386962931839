import Axios from 'axios';
import { omit } from 'lodash';
import { UpdateAddressInCartRequest } from 'models/Address';
import { PaymentMethod } from 'models/Finance';
import Product from 'models/Product';
import {
  UpdateReasonRequest,
  UpdateNoteRequest,
  UpdateProductQuantityRequest,
  UpdateProductAfterCheckoutReq,
} from 'models/Requests';

export const updateCartReason = async (req: UpdateReasonRequest) => {
  const res = await Axios.patch(`/cart-service/api/carts/details/${req.cartId}`, {
    reason: req.reason,
  });
  return res.data;
};

export const updateCartNote = async (req: UpdateNoteRequest) => {
  const res = await Axios.post(`/cart-service/api/carts/invoice-note`, {
    ids: req.ids,
    invoiceNote: req.note,
  });
  return res.data;
};

export const reindexCarts = async () => {
  return await Axios.post('/cart-service/api/carts/reload-carts');
};

export const createCartManually = async ({
  reason,
  products,
  postId,
  customerId,
}: {
  reason: string;
  products: { id?: string; quantity?: number }[];
  postId?: string;
  customerId?: string;
}) => {
  return await Axios.post('/cart-service/api/carts/cart-empty', {
    reason,
    products,
    postId,
    customerId,
  });
};

export const getExistingCart = async (customerId: string) => {
  const res = await Axios.get(`/cart-service/api/carts/latest-pending/${customerId}/customers`);
  return res.data;
};

export const updateExistingCart = async ({
  cartId,
  reason,
  products,
}: {
  cartId: string;
  reason: string;
  products: { id?: string; productQuantity?: number; postId?: string }[];
}) => {
  return await Axios.put(`/cart-service/api/carts/${cartId}/products`, {
    reason,
    products,
  });
};

export const getPrepareSourcingCount = async (filter: {
  productAddedFrom?: string;
  productAddedTo?: string;
  productGroupIds?: string[];
  productIds?: string[];
}) => {
  const res = await Axios.post('/cart-service/api/prepare-sourcing', filter);
  return res.data;
};

export const exportPrepareSourcing = async (filter: {
  productAddedFrom?: string;
  productAddedTo?: string;
  productGroupIds?: string[];
  productIds?: string[];
}) => {
  const res = await Axios.post<string>('/cart-service/api/prepare-sourcing/export', {
    ...filter,
    timezone: new Date().getTimezoneOffset() / -60,
  });
  return res;
};

export const updateAddressInCart = async (req: UpdateAddressInCartRequest) => {
  const res = await Axios.put(`/cart-service/api/carts/delivery-address`, req);
  return res.data;
};

export const uploadPaymentProofs = async ({ cartId, payload }: { cartId: string; payload: string[] }) => {
  const res = await Axios.post(`/cart-service/api/carts/${cartId}/payment-proof`, {
    paymentProof: payload,
  });
  return res.data;
};

export const massCreateProductInCart = async (cartId: string, products: Product[]) => {
  const res = await Axios.post('/cart-service/api/carts/simple-products', {
    cartId,
    products,
  });
  return res.data;
};

export const updateProductQuantity = async (data: UpdateProductQuantityRequest) => {
  const res = await Axios.post('/cart-service/api/carts/change-quantity', data);
  return res.data;
};

export const updateProductAfterCheckout = async (data: UpdateProductAfterCheckoutReq) => {
  const res = await Axios.post('/cart-service/api/carts/change-quantity/v2', data);
  return res.data;
};

export const resetPayAction = async (cartId: string) => {
  const res = await Axios.post(`/cart-service/api/carts/reset-pay-action/${cartId}`);
  return res.data;
};

export const rejectCart = async (cartId: string) => {
  const res = await Axios.post('/cart-service/api/carts/reject', {
    cartId,
  });
  return res.data;
};

export const updateCartDeliveryMethod = async (cartId: string, deliveryId: string, shippingGroupId: string | null) => {
  const res = await Axios.put(`/cart-service/api/carts/${cartId}/delivery-option`, {
    deliveryOption: {
      deliveryOptionId: deliveryId,
      shippingGroupId,
    },
  });
  return res.data;
};

export const getEstimateCart = async (data: {
  cartId: string;
  deliveryOptionId?: string;
  deliveryOptions?: { deliveryOptionId: string; shippingGroupId: string }[];
  paymentMethod?: PaymentMethod;
  country?: string;
  enableCredit?: boolean;
  promotionCode?: string;
}) => {
  const payload = omit(data, 'promotionCode');

  const res = await Axios.post(
    data.promotionCode
      ? `/cart-service/api/carts/estimate?promotionCode=${data.promotionCode}`
      : '/cart-service/api/carts/estimate',
    payload
  );
  return res.data;
};

export const deleteProductInCart = async (data: { cartId?: string; productId?: string; postId?: string }) => {
  const res = await Axios.delete('/cart-service/api/carts/remove-product', {
    data,
  });
  return res.data;
};

export const deleteProductInCartAfterCheckout = async (data: {
  cartId?: string;
  productId?: string;
  postId?: string;
  isRefund?: boolean;
}) => {
  const res = await Axios.delete('/cart-service/api/carts/already-checkout/remove-product', {
    data,
  });
  return res.data;
};

export const approveCart = async (data: { cartId: string; paidAmount?: number; isAddActionRequiredTag?: boolean }) => {
  const res = await Axios.post('/cart-service/api/carts/approve', data);
  return res.data;
};

export const savePaidAmountForPaid = async ({ cartId, paidAmount }: { cartId: string; paidAmount: number }) => {
  const res = await Axios.put(`/cart-service/api/carts/${cartId}/paid-amount`, {
    paidAmount,
  });
  return res.data;
};

export const getProductInCartStatus = async (productIds: string[]) => {
  const res = await Axios.post(`/cart-service/api/carts/status/products`, {
    productIds,
  });
  return res.data;
};

export const addActionRequiredTag = async (cartId: string) => {
  const res = await Axios.post(`/cart-service/api/carts/${cartId}/tags/action-required`);
  return res.data;
};

export const removeActionRequiredTag = async (cartId: string) => {
  const res = await Axios.delete(`/cart-service/api/carts/${cartId}/tags/action-required`);
  return res.data;
};

export const sendCheckoutLink = async (cartId: string) => {
  const res = await Axios.post(`/cart-service/api/carts/${cartId}/checkout-links`);

  return res.data;
};

export const updateSellerAdjustment = async (data: { cartId: string; amount: number; note?: string }) => {
  const res = await Axios.put(`/cart-service/api/carts/adjustment-amount`, data);

  return res.data;
};

export const deleteSellerAdjustment = async (data: { cartId: string }) => {
  const res = await Axios.delete(`/cart-service/api/carts/adjustment-amount`, {
    data,
  });

  return res.data;
};
