import format from 'date-fns/format';
import { max, min } from 'lodash';

import PaymentStatusType from 'models/PaymentStatus';
import { FulfillmentResult } from 'models/Fulfillment';
import { ParcelDeliveryStatus } from 'models/ShipmentDetail';
import { AppsyncEventType } from 'models/LiveSelling';
import { PaymentMethod } from 'models/Finance';
import Order from 'models/Order';
import OrderItem from 'models/OrderItem';
import { isVN } from './constants';
import { formatCurrency } from './functions';
import { SoldListStatus } from 'models/SoldList';
import { DeliveryStatus } from 'models/DeliveryStatus';
import { CustomerType } from 'models/Chat';

export const generatePaymentStatusDetail = (raw?: PaymentStatusType) => {
  switch (raw) {
    case PaymentStatusType.PaymentPaid:
      return {
        label: 'paid',
        color: 'green',
      };
    case PaymentStatusType.Unpaid:
      return {
        label: 'unpaid',
        color: '',
      };
    case PaymentStatusType.PartiallyPaid:
      return {
        label: 'partially_paid',
        color: 'orange',
      };
    default:
      return {
        label: 'unknown',
        color: '',
      };
  }
};

export const generateCurrentDateToName = () => {
  return format(new Date(), 'ddMMyyyy_hhmm');
};

export const generateShipmentDeliveryStatus = (raw: ParcelDeliveryStatus) => {
  switch (raw) {
    case ParcelDeliveryStatus.RequestSentToCourier:
      return 'fulfillment_request_sent';
    case ParcelDeliveryStatus.Picking:
      return 'picking';
    case ParcelDeliveryStatus.PickedUp:
      return 'picked_up';
    case ParcelDeliveryStatus.OnDelivery:
      return 'on_delivery';
    case ParcelDeliveryStatus.Delivered:
      return 'delivered';
    case ParcelDeliveryStatus.FailedDelivery:
      return 'failed_delivery';
    case ParcelDeliveryStatus.Cancelled:
      return 'cancelled';
    default:
      return '';
  }
};

export const removeDuplicateString = (arrValue: string[]) => {
  const uniqueStrings: { [key: string]: boolean } = {};
  const resultArray: string[] = [];

  arrValue.forEach((str) => {
    if (str) {
      const lowercaseStr = str.toLowerCase();
      if (!uniqueStrings[lowercaseStr]) {
        uniqueStrings[lowercaseStr] = true;
        resultArray.push(str);
      }
    }
  });

  const resultString = resultArray.join(', ');
  return resultString;
};

export const generateFieldNameForEvent = (type: AppsyncEventType) => {
  switch (type) {
    case AppsyncEventType.ProductRemainStockChangedDomainEvent:
      return {
        src: 'productStock',
        messageField: 'remainStockChanged',
      };
    case AppsyncEventType.ProductSoldAndReservedChangedDomainEvent:
      return {
        src: 'productReservedAndSold',
        messageField: 'quantityChanged',
      };

    default:
      return {
        src: '',
        messageField: '',
      };
  }
};

export const generateErrorReason = (raw: FulfillmentResult) => {
  if (raw.errorCode === 'ORDERS_NOT_FOUND') {
    return 'order_not_found';
  }

  if (raw.errorCode === 'PARCEL_NOT_NULL') {
    return 'already_arranged';
  }

  if (raw.errorCode === 'DELIVERY_FEE_OUT_OF_RANGE') {
    return 'delivery_fee_out_of_range';
  }

  return `GHTK: ${raw.errorMessage}`;
};

export const generatePaymentTrackingData = (paymentMethodType: PaymentMethod, enabled: boolean) => {
  switch (paymentMethodType) {
    case PaymentMethod.CardStripe:
      return {
        enable_card_payment: enabled,
      };
    case PaymentMethod.PaynowStripe:
      return {
        enable_now_payment: enabled,
      };
    case PaymentMethod.GrabStripe:
      return {
        enable_grab_payment: enabled,
      };

    default:
      return {};
  }
};

export const printBlobFile = async (data: any, type = 'application/pdf') => {
  const pdfBlob = new Blob([data], { type });

  // Create a Blob URL for the PDF Blob
  const pdfBlobUrl = URL.createObjectURL(pdfBlob);

  // Create an iframe to load the PDF Blob
  const iframe = document.createElement('iframe');
  iframe.style.display = 'none';
  document.body.appendChild(iframe);

  // Set the iframe's source to the Blob URL
  iframe.src = pdfBlobUrl;

  // Wait for the iframe to load the PDF Blob
  await new Promise((resolve) => (iframe.onload = resolve));

  if (iframe.contentWindow) {
    const onFocus = () => {
      document.body.removeChild(iframe);
      URL.revokeObjectURL(pdfBlobUrl);
      window.removeEventListener('focus', onFocus);
    };
    window.addEventListener('focus', onFocus);
    iframe.contentWindow?.print();
  }
};

export const getListProductsOfOrder = (raw?: Order): OrderItem[] => {
  if (!raw) {
    return [];
  }
  if (raw.isMaster) {
    return (
      raw.childrenOrders?.reduce<OrderItem[]>((res, cur) => {
        return [...res, ...(cur.orderItems || [])];
      }, []) || []
    );
  }

  return raw.orderItems || [];
};

export const generatePaymentMethod = (method: string) => {
  switch (method) {
    case 'offline':
    case 'Offline':
      if (isVN) return 'bank_transfer';
      return 'offline';
    default:
      return method;
  }
};

export const formatPriceRange = (range?: number[]) => {
  const minPrice = min(range) || 0;
  const maxPrice = max(range) || 0;
  const formattedMinPrice = formatCurrency(minPrice);
  const formattedMaxPrice = formatCurrency(maxPrice);
  if (minPrice === maxPrice) return formattedMinPrice;
  return `${formattedMinPrice} - ${formattedMaxPrice}`;
};

export const generateBidderStatus = (status?: SoldListStatus): string => {
  switch (status) {
    case SoldListStatus.Paid:
      return 'paid';
    case SoldListStatus.Reserved:
      return 'reserved';
    case SoldListStatus.OutOfStock:
      return 'out_of_stock';
    default:
      return '';
  }
};

export const getColumnNameInTableForTracking = (key?: string) => {
  switch (key) {
    case 'productName':
      return 'Product Name';
    case 'productCode':
      return 'Selling code';
    case 'productTotalStock':
      return '# Total Stock';
    case 'productReservedAndSold':
      return '# Sold';
    case 'productStock':
      return '# Remaining Stock';
    case 'created':
      return 'Created';

    default:
      return '';
  }
};

export const generateDeliveryStatusColor = (status: DeliveryStatus) => {
  switch (status) {
    case DeliveryStatus.Paid:
      return {
        backgroundColor: '#FFC53D',
        color: '#000',
      };
    case DeliveryStatus.Packing:
      return {
        backgroundColor: '#FA8C16',
        color: '#000',
      };

    case DeliveryStatus.Shipping:
      return {
        backgroundColor: '#2F54EB',
        color: '#fff',
      };
    case DeliveryStatus.Delivered:
      return {
        backgroundColor: '#389E0D',
        color: '#fff',
      };
    case DeliveryStatus.FailedDelivery:
      return {
        backgroundColor: '#CF1322',
        color: '#fff',
      };
    case DeliveryStatus.Archived:
      return {
        backgroundColor: '#595959',
        color: '#fff',
      };
    default:
      return {};
  }
};

export const convertCustomerType = (type?: string | CustomerType) => {
  switch (type) {
    case CustomerType.FACEBOOK:
    case 'facebook':
      return 'facebook';
    case CustomerType.INSTAGRAM:
    case 'instagram':
      return 'instagram';
    default:
      return 'buyerApp';
  }
};
