/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { CloseCircleOutlined, CloseOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, message, Modal, notification, Space, Spin, Typography } from 'antd';
import { isEmpty, omit, keyBy, groupBy, uniqBy, flatten, isEqual, pickBy, Dictionary, take } from 'lodash';
import { TablePaginationConfig } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';
import { ArgsProps } from 'antd/lib/notification';
import Countdown from 'antd/lib/statistic/Countdown';
import useSWR, { useSWRInfinite } from 'swr';
import moment from 'moment';
import Cookies from 'js-cookie';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';

import { LanguageContext } from 'context/language';
import Product, {
  ProductImage,
  ProductMigrationStatus,
  ProductMigrationStatusKeys,
  ProductMigrationStatusRes,
  ProductStatusKeys,
} from 'models/Product';
import { ProductVariant } from 'models/ProductVariant';
import { requestParamsFromObject, requestWithErrorLogging } from 'utils/request';
import { getErrorsArray, getErrorsObject, getGenericErrors } from 'utils/error';
import { useSegement, useShowError } from 'utils/hooks';
import {
  checkProductsUsedInCarts,
  fetchVariantByIds,
  reindexProducts,
  getProductCode,
  fetchProductByIds,
  massCreateQuickProduct,
  updateProductImage,
  fetchProductDuringLiveStreamById,
  fetchProductAfterLiveStreamById,
} from 'apis/products';
import { reindexCarts } from 'apis/carts';
import { TEMP_ID, TIMEOUT_UPLOAD } from 'utils/constants';
import { getProductVariants } from 'apis/productVariant';
import {
  currentUser,
  formatVariantWithProductInfo,
  generateCommonTrackingData,
  getProductSortKey,
} from 'utils/functions';
import {
  ProductResponseWithES,
  getProductWithES,
  countProductInGroups,
  countProduct,
  getProductWithESV2,
  getProductWithESInfinite,
  getProductInLiveStream,
  getProductWithESInfiniteByCursor,
} from 'apis/search';
import { useFetchFreqUsedVariations } from './productAttribute';

import ErrorModalTitle from 'shared-components/ErrorModalTitle';
import ErrorModalContent from 'shared-components/ErrorModalContent';
import { FILTER_GENERAL_PRODUCTS_OPTION_ID } from 'shared-components/MultiShippingGroupSelect';
import ProductFilter from 'models/ProductFilter';
import { PaginationPayload } from 'models/PaginationPayload';
import { ErrorMessage } from 'models/MassUpload';
import { CapturingSessionApplyOn } from 'models/LiveSelling';

const { Text, Link } = Typography;

const DEFAULT_PAGE_NUMBER = 1;
const DEFAULT_PAGE_SIZE = 10;
const INFINITE_DEFAULT_PAGE_SIZE = 50;

export const DEFAULT_NORMAL_PRODUCT_PAGE_SIZE = 50;
export const PRODUCT_COUNT_URL = '/product-service/api/products/count';

type VariantListResponse = {
  total: number;
  data: ProductVariant[];
};

type FlowFetchProductsByESProps = {
  refreshInterval?: number;
  pagination?: TablePaginationConfig;
  sortedInfo?: SorterResult<Product>;
  search?: string;
  productStatusKey?: ProductStatusKeys;
  selectedProductGroupIds?: string[];
  selectedShippingGroupIds?: string[];
  selectedCatalogueIds?: string[];
  isFetchSingleVariantAsNormal?: boolean;
  isSearchAvailableProductsForBundle?: boolean;
  isIncludeBundleProducts?: boolean;
  isRevalidateOnFocus?: boolean;
  bundleIds?: string[];
  enabled?: boolean;
  excludeIds?: string[];
  includeIds?: string[];
  isFetchFlattenVariants?: boolean;
  capturingSessionId?: string;
  includeDeleted?: boolean;
  sessionType?: 'during' | 'after';
  includeProductWithoutPerformance?: boolean;
};

export const useFetchProductDataByES = ({
  refreshInterval = 0,
  pagination,
  search,
  sortedInfo,
  productStatusKey,
  selectedProductGroupIds,
  selectedShippingGroupIds,
  selectedCatalogueIds,
  isFetchSingleVariantAsNormal = false,
  isSearchAvailableProductsForBundle = false,
  isIncludeBundleProducts = false,
  isRevalidateOnFocus = false,
  bundleIds = [],
  enabled = true,
  excludeIds,
  includeIds,
  isFetchFlattenVariants = false,
  includeDeleted,
}: {
  refreshInterval?: number;
  search?: string;
  pagination?: TablePaginationConfig;
  sortedInfo?: SorterResult<Product>;
  productStatusKey?: ProductStatusKeys;
  selectedProductGroupIds?: string[];
  selectedShippingGroupIds?: string[];
  selectedCatalogueIds?: string[];
  isFetchSingleVariantAsNormal?: boolean;
  isSearchAvailableProductsForBundle?: boolean;
  isIncludeBundleProducts?: boolean;
  isRevalidateOnFocus?: boolean;
  bundleIds?: string[];
  enabled?: boolean;
  excludeIds?: string[];
  includeIds?: string[];
  isFetchFlattenVariants?: boolean;
  includeDeleted?: boolean;
}) => {
  const isIncludeGeneralShippingGroup = selectedShippingGroupIds?.includes(FILTER_GENERAL_PRODUCTS_OPTION_ID);
  const filterShippingGroupIds = isIncludeGeneralShippingGroup
    ? selectedShippingGroupIds?.filter((o) => o !== FILTER_GENERAL_PRODUCTS_OPTION_ID)
    : selectedShippingGroupIds;

  const paginationObject: PaginationPayload = useMemo(() => {
    return {
      _page: pagination?.current || DEFAULT_PAGE_NUMBER,
      _limit: pagination?.pageSize || DEFAULT_PAGE_SIZE,
      ...(isEmpty(sortedInfo) || !sortedInfo?.order
        ? {
            _sort_key: 'product_created_at',
            _sort_direction: 'desc',
          }
        : {
            _sort_key: getProductSortKey(sortedInfo.field as string | number),
            _sort_direction: sortedInfo.order === 'ascend' ? 'asc' : 'desc',
          }),
    };
  }, [pagination, sortedInfo]);

  const filterObject: ProductFilter = useMemo(() => {
    return {
      ...(!productStatusKey ? {} : { product_status: productStatusKey }),
      ...(!isIncludeGeneralShippingGroup ? {} : { is_include_general_shipping_group: isIncludeGeneralShippingGroup }),
      ...(!selectedProductGroupIds?.length ? {} : { product_group_ids: selectedProductGroupIds }),
      ...(!filterShippingGroupIds?.length ? {} : { shipping_group_ids: filterShippingGroupIds }),
      ...(!selectedCatalogueIds?.length ? {} : { product_catalogue_ids: selectedCatalogueIds }),
      ...(isEmpty(search) ? {} : { _keywords: search?.toLowerCase() }),
      ...(!isSearchAvailableProductsForBundle ? {} : { bundle_available_product: isSearchAvailableProductsForBundle }),
      ...(!isIncludeBundleProducts ? {} : { is_include_bundle_products: isIncludeBundleProducts }),
      ...(isEmpty(bundleIds) ? {} : { bundle_ids: bundleIds }),
      ...(isEmpty(excludeIds) ? {} : { exclude_product_ids: excludeIds }),
      ...(!includeIds ? {} : { include_product_ids: includeIds }),
      ...(!includeDeleted ? {} : { include_deleted: includeDeleted }),
    };
  }, [
    productStatusKey,
    isIncludeGeneralShippingGroup,
    selectedProductGroupIds,
    filterShippingGroupIds,
    selectedCatalogueIds,
    search,
    isSearchAvailableProductsForBundle,
    isIncludeBundleProducts,
    bundleIds,
    excludeIds,
    includeIds,
  ]);

  const currentProductsQueryString = useMemo(() => {
    if (!enabled) return null;
    return `/products${requestParamsFromObject({ ...paginationObject, ...filterObject })}`;
  }, [enabled, paginationObject, filterObject]);

  const {
    data: response,
    isValidating: isLoadingProducts,
    revalidate,
  } = useSWR<ProductResponseWithES>(
    currentProductsQueryString,
    getProductWithES({
      pagination: paginationObject,
      filter: filterObject,
      isFetchSingleVariantAsNormal,
      isFetchFlattenVariants,
    }),
    {
      ...(refreshInterval ? { refreshInterval: refreshInterval } : {}),
      revalidateOnFocus: isRevalidateOnFocus,
    }
  );

  return {
    data: response?.data,
    total: response?.total,
    productAndVariantCount: response?.productAndVariantCount,
    rawDataFromES: response?.rawDataFromES,
    isLoadingProducts,
    currentProductsQueryString,
    revalidate,
  };
};

export const useFetchProductData = ({
  refreshInterval = 0,
  enabled = true,
  pagination,
  search,
  sortedInfo,
  productStatusKey,
  selectedProductGroupIds,
  selectedShippingGroupIds,
  groupByMaster = true,
}: {
  refreshInterval?: number;
  enabled?: boolean;
  search?: string;
  pagination?: TablePaginationConfig;
  sortedInfo?: SorterResult<Product>;
  productStatusKey?: ProductStatusKeys;
  selectedProductGroupIds?: string[];
  selectedShippingGroupIds?: string[];
  groupByMaster?: boolean;
}) => {
  const currentProductQueryString = useMemo(() => {
    if (!enabled) return null;

    const isIncludeGeneralShippingGroup = selectedShippingGroupIds?.includes(FILTER_GENERAL_PRODUCTS_OPTION_ID);
    const filterShippingGroupIds = isIncludeGeneralShippingGroup
      ? selectedShippingGroupIds?.filter((o) => o !== FILTER_GENERAL_PRODUCTS_OPTION_ID)
      : selectedShippingGroupIds;
    const paramsObject = {
      pageNumber: pagination?.current || DEFAULT_PAGE_NUMBER,
      pageSize: pagination?.pageSize || DEFAULT_PAGE_SIZE,
      ...(!productStatusKey ? {} : { status: productStatusKey }),
      ...(!isIncludeGeneralShippingGroup ? {} : { includeGeneralShippingGroup: isIncludeGeneralShippingGroup }),
      ...(!selectedProductGroupIds?.length ? {} : { productGroupIds: selectedProductGroupIds.join(',') }),
      ...(!filterShippingGroupIds?.length ? {} : { shippingGroupIds: filterShippingGroupIds.join(',') }),
      ...(isEmpty(search) ? {} : { searchTerm: search }),
      ...(groupByMaster ? {} : { groupByMaster }),
      ...(isEmpty(sortedInfo) || !sortedInfo?.order
        ? {
            orderBy: 'created',
            orderDirection: 'desc',
          }
        : {
            orderBy: sortedInfo.field as string | number,
            orderDirection: sortedInfo.order === 'ascend' ? 'asc' : 'desc',
          }),
    };
    return `/product-service/api/products${requestParamsFromObject(paramsObject)}`;
  }, [
    enabled,
    groupByMaster,
    pagination,
    productStatusKey,
    search,
    selectedProductGroupIds,
    selectedShippingGroupIds,
    sortedInfo,
  ]);

  const {
    data: productData,
    isValidating: isLoadingProducts,
    revalidate: refetchProducts,
  } = useSWR<{
    total: number;
    data: Product[];
  }>(currentProductQueryString, {
    ...(refreshInterval ? { refreshInterval: refreshInterval } : {}),
    revalidateOnFocus: true,
  });

  return {
    currentProductQueryString: currentProductQueryString,
    isLoadingProducts,
    productData,
    refetchProducts,
  };
};

export const useFetchProductCount = ({
  refreshInterval,
  enabled = true,
}: {
  refreshInterval?: number;
  enabled?: boolean;
}) => {
  const { data: productCount } = useSWR<{ active: number; inactive: number }>(enabled ? PRODUCT_COUNT_URL : null, {
    ...(refreshInterval ? { refreshInterval: refreshInterval } : {}),
    revalidateOnFocus: true,
  });

  return {
    productCount,
  };
};

export const useActivateDeactivateProduct = ({
  mutatePaths = [],
  onSuccess,
}: {
  mutatePaths?: string[];
  onSuccess?: () => void;
}): {
  updating: Dictionary<boolean>;
  handleConfirmDeactivate: (params: () => void) => void;
  handleActivateDeactivateProduct: (params: {
    productStatus: ProductStatusKeys;
    productIds: string[];
  }) => Promise<void>;
} => {
  const { translate } = useContext(LanguageContext);
  const { showError } = useShowError();

  const { analyticsTrack } = useSegement();

  const [updating, setUpdating] = useState<Dictionary<boolean>>({
    [ProductStatusKeys.Active]: false,
    [ProductStatusKeys.Inactive]: false,
  });

  const handleActivateDeactivateProduct = useCallback(
    async ({ productStatus, productIds }: { productStatus: ProductStatusKeys; productIds: string[] }) => {
      setUpdating({ [productStatus]: true });
      const status = productStatus === ProductStatusKeys.Active ? 'activate' : 'deactivate';
      const bulkUpdate = productIds.length ? '' : '/bulk';
      const commonTrackingEventData = generateCommonTrackingData();

      try {
        await requestWithErrorLogging({
          url: `/product-service/api/products/${status}${bulkUpdate}`,
          method: 'put',
          data: productIds.length && productIds,
          mutateConfig: {
            mutatePath: [PRODUCT_COUNT_URL, ...mutatePaths],
          },
        });
        onSuccess?.();
        analyticsTrack('Activated Product', {
          action: status,
          product_ids: productIds,
          num_products: productIds.length,
          ...commonTrackingEventData,
        });
      } catch (err) {
        const genericErrors = getGenericErrors(err);
        const errorTitleKey =
          productStatus === ProductStatusKeys.Active ? 'activate_product_error' : 'deactivate_product_error';
        showError(genericErrors, translate(errorTitleKey), translate('error_occurred_try_again'));
      } finally {
        setUpdating({ [productStatus]: false });
      }
    },
    [analyticsTrack, mutatePaths, showError, translate]
  );

  const handleConfirmDeactivate = useCallback(
    (onConfirm: () => void) => {
      Modal.confirm({
        title: translate('deactivate_product'),
        icon: <ExclamationCircleOutlined />,
        content: translate('deactivate_product_warning'),
        okText: translate('proceed'),
        okType: 'primary',
        cancelText: translate('cancel'),
        onOk: onConfirm,
      });
    },
    [translate]
  );

  return {
    updating,
    handleConfirmDeactivate,
    handleActivateDeactivateProduct,
  };
};

export const useProductManagementController = ({
  mutateProductPaths,
  onSuccess,
}: {
  mutateProductPaths?: string[];
  onSuccess?: () => void;
}) => {
  const { translate } = useContext(LanguageContext);
  const { showError } = useShowError();
  const { analyticsTrack } = useSegement();
  const location = useLocation();
  const [modal, contextHolder] = Modal.useModal();

  const { variations: freqUsedVariations } = useFetchFreqUsedVariations(true);

  const freqUsedVariationsValue = useMemo(
    () =>
      freqUsedVariations?.map(
        (item) => `${item.attributeName}:${item.attributeOptions?.map((item) => item?.optionName || '').join(',')}`
      ) || [],
    [freqUsedVariations]
  );

  const [selectedProducts, setSelectedProducts] = useState<Dictionary<Product>>({});
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [selectedProductVariants, setSelectedProductVariants] = useState<Dictionary<ProductVariant[]>>({});
  const [triggerRevalidateVariants, setTriggerRevalidateVariants] = useState(false);

  const selectedProductIds = useMemo(() => Object.keys(selectedProducts), [selectedProducts]);
  const selectedProductVariantIds: { [id: string]: string[] } = useMemo(
    () =>
      Object.keys(selectedProductVariants).reduce((acc, productId) => {
        return { ...acc, [productId]: [...selectedProductVariants[productId].map((o) => o?.id || '')] };
      }, {}),
    [selectedProductVariants]
  );

  const handleClearSelectedProducts = React.useCallback(() => {
    setSelectedProducts({});
  }, []);

  const handleSelectProductVariants = React.useCallback(
    (productId: string) => (productVariantIds: ProductVariant[]) => {
      if (isEmpty(productVariantIds)) return setSelectedProductVariants((old) => omit(old, productId));
      return setSelectedProductVariants((old) => ({
        ...old,
        [productId]: productVariantIds,
      }));
    },
    []
  );

  const handleClearSelectedProductVariants = React.useCallback(() => {
    setSelectedProductVariants({});
  }, []);

  // --- reset sold tracking
  const resetSoldTrackingProducts = React.useCallback(
    async (productIds?: string[]) => {
      const commonTrackingEventData = generateCommonTrackingData();
      setIsUpdating(true);
      try {
        await requestWithErrorLogging({
          url: `/product-service/api/products/sold-tracking`,
          method: 'put',
          data: productIds
            ? {
                ids: productIds,
              }
            : {},
          mutateConfig: {
            mutatePath: mutateProductPaths || [],
          },
        });
        setTriggerRevalidateVariants(true);
        analyticsTrack('Actions Taken in Product', {
          product_id: isEmpty(productIds) ? 'all' : productIds,
          action: isEmpty(productIds) ? 'reset all sold tracking' : 'reset sold tracking',
          ...commonTrackingEventData,
        });
      } catch (err) {
        const genericErrors = getGenericErrors(err);
        showError(
          genericErrors,
          translate('reset_sold_tracking_product_error'),
          translate('reset_sold_tracking_product_error_description')
        );
      } finally {
        setTriggerRevalidateVariants(false);
        setIsUpdating(false);
      }
    },
    [analyticsTrack, mutateProductPaths, showError, translate]
  );

  const confirmResetSoldTrackingProducts = React.useCallback(
    ({ productsLength, productIds }: { productsLength?: number; productIds?: string[] }) => {
      Modal.confirm({
        title: translate('reset_sold_tracking_number_product', {
          '[NUMBER]': (productIds ? productIds.length : productsLength || 0).toString(),
        }),
        icon: <ExclamationCircleOutlined />,
        content: translate('this_action_cannot_be_undone'),
        okText: translate('reset'),
        okType: 'danger',
        cancelText: translate('cancel'),
        onOk: () => {
          resetSoldTrackingProducts(productIds);
        },
      });
    },
    [resetSoldTrackingProducts, translate]
  );

  // create product with variants
  const handleCreateUpdateProductWithVariants = React.useCallback(
    async (
      createUpdateVariantsData: Product,
      currentTab: ProductStatusKeys,
      oldProduct?: Product,
      isCopied?: boolean
    ) => {
      const commonTrackingEventData = generateCommonTrackingData();
      setIsUpdating(true);
      try {
        const productId = await requestWithErrorLogging(
          createUpdateVariantsData?.id
            ? {
                url: `/product-service/api/products/master-products/${createUpdateVariantsData.id}`,
                method: 'patch',
                data: omit(createUpdateVariantsData, ['productCode']),
              }
            : {
                url: '/product-service/api/products/master-products',
                method: 'post',
                data: createUpdateVariantsData,
              }
        );
        message.success(
          translate(createUpdateVariantsData?.id ? 'product_updated_successfully' : 'product_created_successfully')
        );

        if (oldProduct?.id) {
          analyticsTrack('Actions Taken in Product', {
            product_id: [createUpdateVariantsData.id],
            action: 'edit',
            ...commonTrackingEventData,
          });
          analyticsTrack('Product Updated', {
            product_id: createUpdateVariantsData.id,
            edit_name: oldProduct.productName !== createUpdateVariantsData.productName,
            edit_images: !isEqual(oldProduct.productImages, createUpdateVariantsData.productImages),
            edit_price: oldProduct.price !== createUpdateVariantsData.price,
            edit_maximun_quantity_per_post:
              oldProduct.maximumQuantityPerPost !== createUpdateVariantsData.maximumQuantityPerPost,
            edit_description: oldProduct.description !== createUpdateVariantsData.description,
            edit_cost_price: oldProduct.costPrice !== createUpdateVariantsData.costPrice,
            edit_variations: !isEqual(oldProduct.attributes, createUpdateVariantsData.attributes),
            edit_stock: oldProduct.productStock !== createUpdateVariantsData.productStock,
            mass_update: false,
            no_of_products_updated: 1,
            tab: currentTab === ProductStatusKeys.Active ? 'active' : 'inactive',
            edit_product_category: oldProduct.categoryId !== createUpdateVariantsData.categoryId,
            ...commonTrackingEventData,
          });
        } else {
          const variationsValue =
            createUpdateVariantsData.attributes?.map(
              (item) => `${item.attributeName}:${item.options?.map((item) => item?.optionName?.trim() || '').join(',')}`
            ) || [];
          const isUsedFreqUsedVariations = variationsValue?.some((item) => freqUsedVariationsValue.includes(item));

          if (isCopied) {
            analyticsTrack('Actions Taken in Product', {
              product_id: [productId.data[0]],
              action: 'copy',
              ...commonTrackingEventData,
            });
          }
          analyticsTrack('Product Created', {
            product_id: productId.data[0],
            mass_create: false,
            product_category: !!createUpdateVariantsData.categoryId,
            with_variations: true,
            image: !isEmpty(createUpdateVariantsData.productImages),
            description: !!createUpdateVariantsData.description,
            weight: !!createUpdateVariantsData.weight,
            dimensions:
              !!createUpdateVariantsData.width ||
              !!createUpdateVariantsData.height ||
              !!createUpdateVariantsData.length,
            cost_price: createUpdateVariantsData.variants?.some((variation) => !!variation.costPrice),
            frequency_applied: isUsedFreqUsedVariations,
            button: !isCopied ? 'create a new product' : 'copy a product',
            num_products_created: 1,
            type: location.pathname.includes('product/quick-create') ? 'no variation' : 'with variation',
            ...commonTrackingEventData,
          });
        }
      } catch (err) {
        const genericErrors = getErrorsArray(err);
        const affectedIdsString = genericErrors.find((error) => error.includes('{"cartIds":['));
        if (affectedIdsString) {
          const affectedIds = JSON.parse(affectedIdsString);
          Modal.error({
            title: <ErrorModalTitle />,
            content: <ErrorModalContent affectedIds={affectedIds} />,
            icon: <CloseCircleOutlined />,
            width: 600,
          });
        } else {
          return Promise.reject(err);
        }
      } finally {
        setIsUpdating(false);
      }
    },
    [analyticsTrack, translate, freqUsedVariationsValue, location]
  );

  const handleCreateUpdateProductNoVariant = React.useCallback(
    async (createUpdateData: Product, currentTab: ProductStatusKeys, oldProduct?: Product, isCopied?: boolean) => {
      const commonTrackingEventData = generateCommonTrackingData();
      setIsUpdating(true);
      try {
        const productId = await requestWithErrorLogging(
          createUpdateData?.id
            ? {
                url: `/product-service/api/products/${createUpdateData.id}`,
                method: 'patch',
                data: createUpdateData,
              }
            : {
                url: '/product-service/api/products',
                method: 'post',
                data: createUpdateData,
              }
        );
        message.success(
          translate(createUpdateData?.id ? 'product_updated_successfully' : 'product_created_successfully')
        );
        if (oldProduct?.id) {
          analyticsTrack('Actions Taken in Product', {
            product_id: [createUpdateData.id],
            action: 'edit',
            ...commonTrackingEventData,
          });
          analyticsTrack('Product Updated', {
            product_id: createUpdateData.id,
            edit_name: oldProduct.productName !== createUpdateData.productName,
            edit_images: !isEqual(oldProduct.productImages, createUpdateData.productImages),
            edit_price: oldProduct.price !== createUpdateData.price,
            edit_maximun_quantity_per_post:
              oldProduct.maximumQuantityPerPost !== createUpdateData.maximumQuantityPerPost,
            edit_description: oldProduct.description !== createUpdateData.description,
            edit_cost_price: oldProduct.costPrice !== createUpdateData.costPrice,
            edit_stock: oldProduct.productStock !== createUpdateData.productStock,
            mass_update: false,
            no_of_products_updated: 1,
            edit_variations: !isEqual(oldProduct.attributes, createUpdateData.attributes),
            tab: currentTab === ProductStatusKeys.Active ? 'active' : 'inactive',
            edit_product_category: oldProduct.categoryId !== createUpdateData.categoryId,
            ...commonTrackingEventData,
          });
        } else {
          if (isCopied) {
            analyticsTrack('Actions Taken in Product', {
              product_id: [productId.data],
              action: 'copy',
              ...commonTrackingEventData,
            });
          }
          analyticsTrack('Product Created', {
            product_id: productId.data,
            mass_create: false,
            product_category: !!createUpdateData.categoryId,
            with_variations: false,
            image: !isEmpty(createUpdateData.productImages),
            description: !!createUpdateData.description,
            weight: !!createUpdateData.weight,
            dimensions: !!createUpdateData.width || !!createUpdateData.height || !!createUpdateData.length,
            cost_price: !!createUpdateData.costPrice,
            frequency_applied: false,
            button: !isCopied ? 'create a new product' : 'copy a product',
            num_products_created: 1,
            type: location.pathname.includes('product/quick-create') ? 'no variation' : 'with variation',
            ...commonTrackingEventData,
          });
        }
      } catch (err) {
        const genericErrors = getErrorsArray(err);
        const affectedIdsString = genericErrors.find((error) => error.includes('{"cartIds":['));
        if (affectedIdsString) {
          const affectedIds = JSON.parse(affectedIdsString);
          Modal.error({
            title: <ErrorModalTitle />,
            content: <ErrorModalContent affectedIds={affectedIds} />,
            icon: <CloseCircleOutlined />,
            width: 600,
          });
        } else {
          return Promise.reject(err);
        }
      } finally {
        setIsUpdating(false);
      }
    },
    [analyticsTrack, translate, location]
  );

  // --- delete products
  const handleDeleteProducts = React.useCallback(
    async (productIds: string[]) => {
      if (productIds.length > 0) {
        const commonTrackingEventData = generateCommonTrackingData();
        setIsUpdating(true);
        try {
          await requestWithErrorLogging({
            url: `/product-service/api/products`,
            method: 'delete',
            data: {
              ids: productIds,
            },
            mutateConfig: {
              mutatePath: [PRODUCT_COUNT_URL, ...(mutateProductPaths || [])],
            },
          });
          analyticsTrack('Actions Taken in Product', {
            product_id: productIds,
            action: 'delete',
            ...commonTrackingEventData,
          });
          message.success(
            translate('product_deleted_successfully', {
              '[NUMBER]': productIds.length,
            })
          );
          onSuccess?.();
        } catch (err) {
          const errors = getErrorsArray(err);
          const affectedIdsString = errors.find((error) => error.includes('{"cartIds":['));
          if (affectedIdsString) {
            const affectedIds = JSON.parse(affectedIdsString);
            modal.error({
              title: <ErrorModalTitle />,
              content: <ErrorModalContent affectedIds={affectedIds} />,
              icon: <CloseCircleOutlined />,
              width: 600,
            });
          } else {
            showError(errors, translate('delete_product_error'), translate('error_occurred_try_again'));
          }
        } finally {
          setIsUpdating(false);
        }
      }
    },
    [mutateProductPaths, analyticsTrack, translate, onSuccess, modal, showError]
  );

  const confirmDeleteProducts = React.useCallback(
    async (products: Product[], productIds: string[]) => {
      const existedProductIds = await checkProductsUsedInCarts(productIds);
      const existedProductsById = keyBy(existedProductIds);
      const groupProduct = groupBy(
        products,
        (product) => product.isMaster || product.productVariantValues?.length === 1
      );
      const normalProductNames = groupProduct['false']
        ?.reduce<(string | undefined)[]>(
          (res, cur) => (existedProductsById[cur.id || ''] ? [...res, cur.productName] : res),
          []
        )
        .join(', ');
      const masterProductName = groupProduct['true']
        ?.reduce<(string | undefined)[]>(
          (res, cur) => (existedProductsById[cur.masterProductId || cur.id || ''] ? [...res, cur.productName] : res),
          []
        )
        .join(', ');

      Modal.confirm({
        title:
          existedProductIds.length > 0 ? (
            <Space wrap>
              {!!normalProductNames && (
                <p>
                  {translate('there_are_pending_orders_that_contain_product', {
                    '[PRODUCT_NAMES]': normalProductNames,
                  })}
                </p>
              )}
              {!!masterProductName && (
                <p>
                  {translate('there_are_pending_orders_that_contain_variant_product', {
                    '[PRODUCT_NAMES]': masterProductName,
                  })}
                </p>
              )}
              <p>{translate('are_you_sure_want_to_process')}</p>
            </Space>
          ) : (
            translate('delete_number_products', { '[NUMBER]': productIds.length?.toString() })
          ),
        icon: <ExclamationCircleOutlined />,
        content: translate('this_action_cannot_be_undone'),
        okText: translate(existedProductIds.length > 0 ? 'proceed' : 'delete'),
        okType: 'danger',
        cancelText: translate('cancel'),
        onOk: () => {
          handleDeleteProducts(productIds);
        },
        width: existedProductIds.length > 0 ? 600 : 400,
      });
    },
    [handleDeleteProducts, translate]
  );

  return {
    isUpdating,
    confirmResetSoldTrackingProducts,
    handleDeleteProducts,
    confirmDeleteProducts,
    selectedProducts,
    selectedProductIds,
    handleClearSelectedProducts,
    setSelectedProducts,
    ModalContextHolder: contextHolder,
    selectedProductVariants,
    selectedProductVariantIds,
    setSelectedProductVariants,
    handleSelectProductVariants,
    handleClearSelectedProductVariants,
    handleCreateUpdateProductWithVariants,
    handleCreateUpdateProductNoVariant,
    setTriggerRevalidateVariants,
    triggerRevalidateVariants,
  };
};

export const useUploadProducts = ({
  currentProductQueryString,
  isFlag,
}: {
  currentProductQueryString: string;
  isFlag: boolean;
}) => {
  const { translate } = useContext(LanguageContext);
  const { analyticsTrack } = useSegement();

  const [isUploading, setIsUploading] = React.useState(false);
  const [errors, setErrors] = React.useState<ErrorMessage[]>([]);
  const [success, setSuccess] = React.useState<{
    totalProducts?: number;
    totalVariations?: number;
  }>({});

  const handleReset = useCallback(() => {
    setErrors([]);
    setSuccess({});
  }, []);

  const handleUploadProductCsv = React.useCallback(
    async ({ file, isUpdate, onSuccess }: { file: File; isUpdate?: boolean; onSuccess?: (ids: string[]) => void }) => {
      const url = `product-service/api/products/import?isUpdate=${!!isUpdate}`;
      const data = new FormData();
      data.append('FileUpload', file);
      const commonTrackingEventData = generateCommonTrackingData();
      setIsUploading(true);
      try {
        const res = await requestWithErrorLogging({
          url: url,
          method: 'post',
          data,
          timeout: TIMEOUT_UPLOAD,
          mutateConfig: {
            mutatePath: [currentProductQueryString],
          },
        });
        setIsUploading(false);
        if (isFlag) {
          message.success(
            translate(isUpdate ? 'number_product_updated_successfully' : 'number_product_created_successfully', {
              '[NUMBER]': res.data.totalProducts,
            })
          );
        } else {
          setSuccess({
            totalProducts: res.data.totalProducts,
            totalVariations: res.data.totalVariations,
          });
        }
        if (isUpdate) {
          analyticsTrack('Product Updated', {
            mass_update: true,
            no_of_products_updated: res.data.totalProducts,
            ...commonTrackingEventData,
          });
        } else {
          analyticsTrack('Product Created', {
            mass_create: true,
            num_products_created: res.data.totalProducts,
            ...commonTrackingEventData,
          });
        }
        const normalIds = res?.data?.normalProductIds || [];
        const variantIds = res?.data?.variantProductIds || [];
        onSuccess?.([...normalIds, ...variantIds]);
      } catch (error: any) {
        setIsUploading(false);
        const { data } = error.response;
        if (typeof data === 'string') return message.error(data);
        let errorTitle = data.detail || data.title;
        if (isFlag) {
          const genericErrors = getGenericErrors(error);
          if (genericErrors && genericErrors.length > 0) {
            errorTitle = translate('please_fix_below_error_and_submit_again');
          }
          return Modal.error({
            title: errorTitle,
            icon: <CloseCircleOutlined />,
            content: data.errors && (
              <div className="overflow-y-auto" style={{ maxHeight: 300 }}>
                {genericErrors.map((error) => (
                  <div key={`${error}-${moment().valueOf()}`}>
                    <p>{translate(error)}</p>
                    <br />
                  </div>
                ))}
              </div>
            ),
            width: 500,
          });
        }
        const status = error.response.data.status;
        if (status === 500) return message.error(error.response.data.title);
        const genericErrors = getErrorsObject(error);
        return setErrors(genericErrors);
      }
    },
    [analyticsTrack, currentProductQueryString, setIsUploading, translate, isFlag]
  );

  return {
    isUploading,
    handleUploadProductCsv,
    errors,
    success,
    handleReset,
  };
};

export const useFetchProductVariantData = ({
  enable = true,
  refreshInterval = 0,
  productId,
  pagination,
  sortedInfo,
  productStatusKey,
  selectedProductGroupIds,
  selectedShippingGroupIds,
}: {
  enable?: boolean;
  refreshInterval?: number;
  productId: string;
  pagination?: TablePaginationConfig;
  sortedInfo?: SorterResult<Product>;
  productStatusKey?: ProductStatusKeys;
  selectedProductGroupIds?: string[];
  selectedShippingGroupIds?: string[];
}) => {
  const currentProductVariantQueryString: string = useMemo(() => {
    const isIncludeGeneralShippingGroup = selectedShippingGroupIds?.includes(FILTER_GENERAL_PRODUCTS_OPTION_ID);
    const filterShippingGroupIds = isIncludeGeneralShippingGroup
      ? selectedShippingGroupIds?.filter((o) => o !== FILTER_GENERAL_PRODUCTS_OPTION_ID)
      : selectedShippingGroupIds;
    const paramsObject = {
      pageNumber: pagination?.current || DEFAULT_PAGE_NUMBER,
      pageSize: pagination?.pageSize || DEFAULT_PAGE_SIZE,
      ...(!productStatusKey ? {} : { status: productStatusKey }),
      ...(!isIncludeGeneralShippingGroup ? {} : { includeGeneralShippingGroup: isIncludeGeneralShippingGroup }),
      ...(!selectedProductGroupIds?.length ? {} : { productGroupIds: selectedProductGroupIds.join(',') }),
      ...(!filterShippingGroupIds?.length ? {} : { shippingGroupIds: filterShippingGroupIds.join(',') }),
      ...(isEmpty(sortedInfo) || !sortedInfo?.order
        ? {
            orderBy: 'created',
            orderDirection: 'desc',
          }
        : {
            orderBy: sortedInfo.field as string | number,
            orderDirection: sortedInfo.order === 'ascend' ? 'asc' : 'desc',
          }),
    };
    return `/product-service/api/products/${productId}/variants${requestParamsFromObject(paramsObject)}`;
  }, [pagination, productId, productStatusKey, selectedProductGroupIds, selectedShippingGroupIds, sortedInfo]);

  const { data: productVariantData, isValidating: isLoadingVariants } = useSWR<{
    total: number;
    data: ProductVariant[];
  }>(enable ? currentProductVariantQueryString : null, {
    ...(refreshInterval ? { refreshInterval: refreshInterval } : {}),
    revalidateOnFocus: true,
  });

  return {
    currentProductVariantQueryString,
    isLoadingVariants,
    productVariantData,
  };
};

export const useFetchProductDetails = (id: string | undefined) => {
  const currentProductDetailsQueryString = useMemo(() => (id ? `/product-service/api/products/${id}` : null), [id]);

  const { data: productDetailsData, isValidating: isLoadingProductDetails } = useSWR<Product>(
    currentProductDetailsQueryString
  );

  return {
    isLoadingProductDetails,
    productDetailsData,
  };
};

export const useFetchProductVariantsInfinite = ({
  productId,
  pagination,
  refreshInterval = 0,
  currentProductStatus,
}: {
  refreshInterval?: number;
  productId: string;
  pagination?: TablePaginationConfig;
  currentProductStatus?: ProductStatusKeys;
}) => {
  const getVariantKey = (pageIndex: number, previousPageData: VariantListResponse | null) => {
    if (!productId || (previousPageData && !previousPageData.data.length)) return null;
    const statusParamString = currentProductStatus ? `&status=${currentProductStatus}` : '';

    return `/product-service/api/products/${productId}/variants?pageNumber=${pageIndex + 1}&pagesize=${
      pagination?.pageSize || DEFAULT_PAGE_SIZE
    }${statusParamString}`;
  };

  const { data, size, setSize, isValidating, error, revalidate } = useSWRInfinite<VariantListResponse>(getVariantKey, {
    revalidateOnFocus: false,
    ...(refreshInterval ? { refreshInterval: refreshInterval } : {}),
  });

  const variants = React.useMemo(() => {
    return (
      data?.reduce<ProductVariant[]>((res, cur) => {
        return [...res, ...(cur?.data || [])];
      }, []) || []
    );
  }, [data]);

  const total = React.useMemo(() => data?.[0].total || 0, [data]);

  return {
    variants,
    total,
    size,
    isValidating,
    error,
    setSize,
    revalidate,
  };
};

export const useGetVariantsOfMultiProducts = ({
  productIds,
  pagination,
}: {
  productIds: string[];
  pagination?: TablePaginationConfig;
}) => {
  const { data, isValidating } = useSWR<{ [key in string]: ProductVariant[] }>(
    productIds?.length ? productIds.join('-') : null,
    getProductVariants(productIds, pagination)
  );

  return { variants: data, loadingVariants: isValidating };
};

export const useChooseProductToAddController = () => {
  const [productsInGroup, setProductsInGroup] = useState<Product[]>([]);
  const [deletedProduct, setDeletedProduct] = useState<string[]>([]);
  const [variantsInGroup, setVariantsInGroup] = useState<{ [productId: string]: ProductVariant[] }>({});
  const [deletedVariants, setDeletedVariants] = useState<{ [productId: string]: string[] }>({});

  const totalVariantIdsInGroup = React.useMemo(
    () => flatten(Object.values(variantsInGroup)).map((o) => o?.id || ''),
    [variantsInGroup]
  );

  const handleDeleteMultiProducts = useCallback(
    (productIds: string[], variantIds: { [productId: string]: string[] }) => {
      const productWithVariantIds = Object.keys(variantIds);

      const deletedProductsWithOneVariant =
        productsInGroup.reduce<string[]>(
          (productIds, product) =>
            productWithVariantIds.includes(product?.masterProductId || '') &&
            !product.isMaster &&
            !!product.masterProductId
              ? [...productIds, product.masterProductId || '']
              : productIds,
          []
        ) || [];

      setDeletedProduct((prevDeleted) => [...prevDeleted, ...productIds]);
      setProductsInGroup((prevProductsInGroup) =>
        prevProductsInGroup?.filter(
          (product) =>
            product.id &&
            !productIds.includes(product.id) &&
            !deletedProductsWithOneVariant.includes(product?.masterProductId || '')
        )
      );

      setDeletedVariants((prevDeletedVariants) => ({
        ...prevDeletedVariants,
        ...productWithVariantIds.reduce((acc, productId) => {
          return { ...acc, [productId]: [...(prevDeletedVariants[productId] || []), ...variantIds[productId]] };
        }, {}),
      }));
      setVariantsInGroup((prevVariantsInGroup) => ({
        ...prevVariantsInGroup,
        ...productWithVariantIds.reduce((acc, productId) => {
          return {
            ...acc,
            [productId]: prevVariantsInGroup[productId]?.filter(
              (variant) => variant?.id && !variantIds[productId].includes(variant.id)
            ),
          };
        }, {}),
      }));
    },
    [productsInGroup]
  );

  const handleDeleteProduct = useCallback(
    (productId: string, isMaster?: boolean) => {
      if (isMaster) {
        setDeletedVariants((prevDeleted) => ({
          ...prevDeleted,
          [productId]: [
            ...(prevDeleted?.[productId] || []),
            ...(variantsInGroup[productId]?.map((o) => o.id || '') || []),
          ],
        }));
        setVariantsInGroup((prevVariants) => ({
          ...prevVariants,
          [productId]: [],
        }));
      } else {
        setProductsInGroup((prevProductInGroup) => prevProductInGroup?.filter((p) => p.id !== productId));
        setDeletedProduct((prevDeletedProduct) => [...prevDeletedProduct, productId]);
      }
    },
    [variantsInGroup]
  );

  const handleDeleteVariant = useCallback((productId: string, variantId: string) => {
    setDeletedVariants((prevDeleted) => ({
      ...prevDeleted,
      [productId]: [...(prevDeleted[productId] || []), variantId],
    }));
    setVariantsInGroup((prevVariants) => ({
      ...prevVariants,
      [productId]: prevVariants[productId]?.filter((o) => o.id !== variantId),
    }));
  }, []);

  // update normal products in group
  const handleAddProducts = useCallback((products: Product[]) => {
    const productIds = products?.map((o) => o.id || '') || [];

    setProductsInGroup((prevProductsInGroup) => [...prevProductsInGroup, ...products]);
    setDeletedProduct((prevDeleted) => prevDeleted?.filter((product) => !productIds.includes(product)) || []);
  }, []);

  // update variants in group
  const handleAddVariants = useCallback((variants: { [productId: string]: ProductVariant[] }) => {
    const productIdsArr = Object.keys(variants) || [];
    const variantIds: { [key: string]: string[] } = productIdsArr.reduce((acc, productId) => {
      return { ...acc, [productId]: [...variants[productId].map((o) => o?.id || '')] };
    }, {});

    setVariantsInGroup((prevVariantInGroup) => ({
      ...prevVariantInGroup,
      ...productIdsArr.reduce((acc, productId) => {
        return {
          ...acc,
          [productId]: uniqBy(
            [...(prevVariantInGroup?.[productId] || []), ...(variants?.[productId] || [])],
            (variant) => variant?.id || ''
          ),
        };
      }, {}),
    }));

    setDeletedVariants((prevDeletedVariants) => ({
      ...prevDeletedVariants,
      ...productIdsArr.reduce((acc, productId) => {
        return {
          ...acc,
          [productId]:
            prevDeletedVariants[productId]?.filter((id: string) => !variantIds[productId]?.includes(id)) || [],
        };
      }, {}),
    }));
  }, []);

  return {
    productsInGroup,
    deletedProduct,
    variantsInGroup,
    totalVariantIdsInGroup,
    deletedVariants,
    handleDeleteMultiProducts,
    handleDeleteProduct,
    handleDeleteVariant,
    handleAddProducts,
    handleAddVariants,
    setDeletedProduct,
    setDeletedVariants,
    setProductsInGroup,
    setVariantsInGroup,
  };
};

export const useChooseProductToAddControllerV2 = () => {
  const [productsInGroup, setProductsInGroup] = useState<Dictionary<Product>>({});
  const [variantsInGroup, setVariantsInGroup] = useState<Dictionary<string[]>>({});
  const [deletedProduct, setDeletedProduct] = useState<Dictionary<string>>({});
  const [deletedVariants, setDeletedVariants] = useState<Dictionary<string[]>>({});

  const totalVariantIdsInGroup = React.useMemo(() => flatten(Object.values(variantsInGroup)), [variantsInGroup]);

  const handleDeleteMultiProducts = useCallback(
    (productIds: Dictionary<string>, variantIds: Dictionary<string[]>) => {
      const productWithVariantIds = Object.keys(variantIds);

      const deletedProductsWithOneVariant =
        Object.keys(productsInGroup).reduce<Dictionary<string>>(
          (productIds, product) =>
            variantIds[productsInGroup[product]?.masterProductId || ''] &&
            !productsInGroup[product].isMaster &&
            !!productsInGroup[product].masterProductId
              ? {
                  ...productIds,
                  [productsInGroup[product].masterProductId || '']: productsInGroup[product].masterProductId || '',
                }
              : productIds,
          {}
        ) || {};

      setDeletedProduct((prevDeleted) => ({ ...prevDeleted, ...productIds }));
      setProductsInGroup((prevProductsInGroup) =>
        pickBy(
          prevProductsInGroup,
          (product) =>
            product.id && !productIds[product.id] && !deletedProductsWithOneVariant[product.masterProductId || '']
        )
      );

      setDeletedVariants((prevDeletedVariants) => ({
        ...prevDeletedVariants,
        ...productWithVariantIds.reduce((acc, productId) => {
          return { ...acc, [productId]: [...(prevDeletedVariants[productId] || []), ...variantIds[productId]] };
        }, {}),
      }));
      setVariantsInGroup((prevVariantsInGroup) => ({
        ...prevVariantsInGroup,
        ...productWithVariantIds.reduce((acc, productId) => {
          return {
            ...acc,
            [productId]: prevVariantsInGroup[productId]?.filter(
              (variant) => variant && !variantIds[productId].includes(variant)
            ),
          };
        }, {}),
      }));
    },
    [productsInGroup]
  );

  const handleDeleteProduct = useCallback(
    (productId: string, isMaster?: boolean) => {
      if (isMaster) {
        setDeletedVariants((prevDeleted) => ({
          ...prevDeleted,
          [productId]: [
            ...(prevDeleted?.[productId] || []),
            ...(variantsInGroup[productId]?.map((variant) => variant) || []),
          ],
        }));
        setVariantsInGroup((prevVariants) => omit(prevVariants, productId));
      } else {
        setProductsInGroup((prevProductInGroup) => pickBy(prevProductInGroup, (product) => product.id !== productId));
        setDeletedProduct((prevDeletedProduct) => ({ ...prevDeletedProduct, [productId]: productId }));
      }
    },
    [variantsInGroup]
  );

  const handleDeleteVariant = useCallback((productId: string, variantIds: string[]) => {
    setDeletedVariants((prevDeleted) => ({
      ...prevDeleted,
      [productId]: [...(prevDeleted[productId] || []), ...variantIds],
    }));
    setVariantsInGroup((prevVariants) => ({
      ...prevVariants,
      [productId]: prevVariants[productId]?.filter((o) => !variantIds.includes(o)),
    }));
  }, []);

  // update normal products in group
  const handleAddProducts = useCallback((products: Dictionary<Product>) => {
    const productIds = Object.keys(products);

    setProductsInGroup((prevProductsInGroup) => ({ ...prevProductsInGroup, ...products }));
    setDeletedProduct((prevDeleted) => omit(prevDeleted, productIds));
  }, []);

  // update variants in group
  const handleAddVariants = useCallback((variants: { [productId: string]: ProductVariant[] }) => {
    const productIdsArr = Object.keys(variants) || [];
    const variantIds: { [key: string]: string[] } = productIdsArr.reduce((acc, productId) => {
      return { ...acc, [productId]: [...variants[productId]] };
    }, {});

    setVariantsInGroup((prevVariantInGroup) => ({
      ...prevVariantInGroup,
      ...productIdsArr.reduce((acc, productId) => {
        return {
          ...acc,
          [productId]: uniqBy(
            [...(prevVariantInGroup?.[productId] || []), ...(variants?.[productId] || [])],
            (variant) => variant
          ),
        };
      }, {}),
    }));

    setDeletedVariants((prevDeletedVariants) => ({
      ...prevDeletedVariants,
      ...productIdsArr.reduce((acc, productId) => {
        return {
          ...acc,
          [productId]:
            prevDeletedVariants[productId]?.filter((id: string) => !variantIds[productId]?.includes(id)) || [],
        };
      }, {}),
    }));
  }, []);

  return {
    productsInGroup,
    deletedProduct,
    variantsInGroup,
    totalVariantIdsInGroup,
    deletedVariants,
    handleDeleteMultiProducts,
    handleDeleteProduct,
    handleDeleteVariant,
    handleAddProducts,
    handleAddVariants,
    setDeletedProduct,
    setDeletedVariants,
    setProductsInGroup,
    setVariantsInGroup,
  };
};

export const useFetchAvailableProductDataInCart = ({
  postIds,
  pagination,
  search,
}: {
  postIds: string[];
  search?: string;
  pagination?: TablePaginationConfig;
}) => {
  const currentProductInCartQueryString = useMemo(() => {
    if (!postIds.length) return null;

    const paramsObject = {
      pageNumber: pagination?.current || DEFAULT_PAGE_NUMBER,
      pageSize: pagination?.pageSize || DEFAULT_PAGE_SIZE,
      ...(isEmpty(search) ? {} : { searchTerm: search }),
    };
    const postIdsString = postIds.map((o) => `postIds=${o}`).join('&');
    return `/cart-service/api/carts/available-products/v2${requestParamsFromObject(paramsObject)}&${postIdsString}`;
  }, [pagination, postIds, search]);

  const {
    data: productData,
    isValidating: isLoadingProducts,
    revalidate: refetchProducts,
  } = useSWR<{
    total: number;
    data: Product[];
  }>(currentProductInCartQueryString);

  return {
    isLoadingProducts,
    productData,
    refetchProducts,
  };
};

export const useCheckProductExistInCart = (productIds?: string[]) => {
  const [usedProductsInCartsIds, setUsedProductsInCartsIds] = useState<string[]>([]);

  const getExistedProductsInCartsIds = React.useCallback(async () => {
    try {
      const res = await checkProductsUsedInCarts(productIds || []);
      setUsedProductsInCartsIds(res);
    } catch (err) {
      /*  */
    }
  }, [productIds, setUsedProductsInCartsIds]);

  React.useEffect(() => {
    if (!isEmpty(productIds)) {
      getExistedProductsInCartsIds();
    }
  }, [getExistedProductsInCartsIds, productIds]);

  return { usedProductsInCartsIds };
};

export const useFetchVariantsByES = ({
  refreshInterval = 0,
  variantIds,
}: {
  refreshInterval?: number;
  variantIds: string[];
}) => {
  const currentVariantQueryString: string = useMemo(() => {
    return `/products/search/variants${variantIds}`;
  }, [variantIds]);

  const {
    data: variantData,
    isValidating: isLoadingVariants,
    revalidate: revalidateES,
  } = useSWR<{
    data: ProductVariant[];
  }>(!isEmpty(variantIds) ? currentVariantQueryString : null, fetchVariantByIds(variantIds), {
    ...(refreshInterval ? { refreshInterval: refreshInterval } : {}),
    revalidateOnFocus: !!variantIds?.length,
  });

  return {
    variantData: variantData?.data,
    isLoadingVariants,
    revalidateES,
  };
};

export const useFlowFetchProductsByES = ({
  refreshInterval,
  pagination,
  sortedInfo,
  search,
  productStatusKey,
  selectedProductGroupIds,
  selectedShippingGroupIds,
  selectedCatalogueIds,
  isFetchSingleVariantAsNormal = false,
  isSearchAvailableProductsForBundle = false,
  isIncludeBundleProducts = false,
  isRevalidateOnFocus = true,
  bundleIds = [],
  enabled = true,
  excludeIds,
  includeIds,
  isFetchFlattenVariants = false,
  includeDeleted,
}: FlowFetchProductsByESProps) => {
  const {
    data,
    total,
    currentProductsQueryString,
    rawDataFromES,
    isLoadingProducts,
    productAndVariantCount,
    revalidate,
  } = useFetchProductDataByES({
    refreshInterval,
    pagination,
    sortedInfo,
    search,
    productStatusKey,
    selectedProductGroupIds,
    selectedShippingGroupIds,
    selectedCatalogueIds,
    isFetchSingleVariantAsNormal,
    isSearchAvailableProductsForBundle,
    isIncludeBundleProducts,
    isRevalidateOnFocus,
    bundleIds,
    enabled,
    excludeIds,
    includeIds,
    isFetchFlattenVariants,
    includeDeleted,
  });

  const productIds = useMemo(() => rawDataFromES?.map((product) => product.product_id || '') || [], [rawDataFromES]);

  const variantIdsList: { [key: string]: string[] } = useMemo(() => {
    const productWithVariants = isFetchSingleVariantAsNormal
      ? rawDataFromES?.filter((product) => (product?.variants?.length || 0) > 1)
      : rawDataFromES?.filter((product) => !isEmpty(product?.variants));
    return (
      productWithVariants?.reduce((acc, product) => {
        return { ...acc, [product.product_id]: product?.variants?.map((o) => o?.product_id || '') || [] };
      }, {}) || {}
    );
  }, [isFetchSingleVariantAsNormal, rawDataFromES]);

  return {
    productData: data,
    total,
    currentProductsQueryString,
    isFetchingByES: isLoadingProducts,
    productIds,
    variantIdsList,
    productAndVariantCount,
    revalidate,
  };
};

export const useFetchProductCountInGroups = ({ groupIds }: { groupIds?: string[] }) => {
  const currentProductCountQueryString: string = useMemo(() => `/products/count${groupIds}`, [groupIds]);

  const {
    data: response,
    isValidating: isCountingProduct,
    revalidate,
  } = useSWR<{ [key: string]: number }>(
    groupIds?.length ? currentProductCountQueryString : null,
    countProductInGroups(groupIds),
    {
      revalidateOnFocus: true,
    }
  );

  return {
    productCountInGroups: response,
    isCountingProduct,
    currentProductCountQueryString,
    revalidate,
  };
};

export const useFetchProductForDuplicating = (id: string | undefined) => {
  const productDetailsQueryString = useMemo(() => (id ? `/product-service/api/products/${id}` : null), [id]);

  const { data: productDetailsData, isValidating: isLoadingProductDetails } =
    useSWR<Product>(productDetailsQueryString);

  const formattedCopiedProduct = React.useMemo(() => {
    if (!productDetailsData) return undefined;

    const newProduct = omit(productDetailsData, ['id', 'productImages', 'productCode']);

    newProduct.attributes =
      productDetailsData?.attributes?.map((o) => ({
        attributeName: o.attributeName,
        productCategoryAttributeId: o.productCategoryAttributeId,
        options:
          o?.options?.map((option) => ({
            ...option,
            optionId: `${TEMP_ID}-${option.optionId}`,
          })) || [],
      })) || [];
    newProduct.variants =
      productDetailsData?.variants?.map((variant) => ({
        ...omit(variant, ['id', 'image']),
        productVariantValues:
          variant.productVariantValues?.map((value) => ({
            attributeName: value.attributeName || '',
            optionId: `${TEMP_ID}-${value.optionId}`,
            optionName: value.optionName || '',
          })) || [],
      })) || [];

    return newProduct;
  }, [productDetailsData]);

  return {
    isLoadingProductDetails,
    formattedCopiedProduct,
  };
};

export const useUpdateVerionSingleProduct = () => {
  const { showError } = useShowError();
  const { translate } = React.useContext(LanguageContext);

  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  const handleUpdateVerion = useCallback(
    async (data: { category: string; productCode: string }) => {
      setIsUpdating(true);
      try {
        const response = await requestWithErrorLogging({
          url: `/product-service/api/products/migrate`,
          method: 'post',
          data,
        });
        return response.data.data;
      } catch (err) {
        const genericErrors = getGenericErrors(err);
        showError(
          genericErrors,
          translate('update_version_product_error'),
          translate('update_version_product_error_description')
        );
        throw err;
      } finally {
        setIsUpdating(false);
      }
    },
    [showError, translate]
  );

  return {
    handleUpdateVerion,
    isUpdating,
  };
};

export const useFetchNormalProductsOnly = ({ isFetch }: { isFetch?: boolean }) => {
  const normalProductsQueryString = useMemo(
    () =>
      isFetch
        ? `/product-service/api/products/normal-products?pageNumber=1&pageSize=${DEFAULT_NORMAL_PRODUCT_PAGE_SIZE}`
        : null,
    [isFetch]
  );

  const { data: normalProducts, isValidating } = useSWR<{ data: Product[]; total: number }>(normalProductsQueryString);

  return {
    normalProducts,
    isFetchingNormalProducts: isValidating,
  };
};

export const useUploadToUpdateProductsVersion = () => {
  const { showError } = useShowError();
  const { translate } = useContext(LanguageContext);

  const [migrationId, setMigrationId] = React.useState<string>('');
  const [isUploading, setIsUploading] = React.useState(false);

  const migrationStatusQueryString = useMemo(
    () => (migrationId ? `/product-service/api/products/migrate/status?migrationId=${migrationId}` : null),
    [migrationId]
  );

  const { data: status } = useSWR<ProductMigrationStatusRes>(migrationStatusQueryString, {
    refreshInterval: 5000,
  });

  const configProcessingMsg: ArgsProps = React.useMemo(
    () => ({
      key: ProductMigrationStatusKeys.Processing,
      message: <></>,
      className: 'migrate-message hide-close-icon',
      getContainer: () => document.querySelector('main.ant-layout-content') || document.body,
      description: (
        <div className="flex justify-between items-center">
          <Spin className="mr-2" />
          <Text strong className="mx-2">
            {translate('processing_not_dot')}
          </Text>
          <Text>
            {translate('number_product_are_updating', {
              '[NUMBER]': status?.total || 0,
            })}
          </Text>
          <Button type="link" onClick={() => notification.close(ProductMigrationStatusKeys.Processing)}>
            {translate('hide')}
          </Button>
        </div>
      ),
      duration: 0,
      bottom: 0,
      placement: 'bottomLeft',
    }),
    [status?.total, translate]
  );

  const configErrorMsg: ArgsProps = React.useMemo(
    () => ({
      key: ProductMigrationStatusKeys.Error,
      message: (
        <>
          {translate('number_product_are_updated_failed', {
            '[NUMBER]': status?.numberOfInvalidProduct || 0,
          })}
        </>
      ),
      className: 'migrate-message',
      getContainer: () => document.querySelector('main.ant-layout-content') || document.body,
      description: (
        <div className="flex justify-between items-center">
          <Text>{translate('please_download_an_excel_file')}</Text>
          &nbsp;
          <Link href={status?.errorReportUrl} target="_blank">
            {translate('here')}
          </Link>
          &nbsp;
          <Text>{translate('with_error_code_and_indicator')}</Text>
        </div>
      ),
      style: {
        border: '1px solid #FFCCC7',
        background: '#FFF1F0',
        padding: '12px 16px',
      },
      duration: 0,
      bottom: 0,
      placement: 'bottomLeft',
    }),
    [status?.errorReportUrl, status?.numberOfInvalidProduct, translate]
  );

  const configSuccessfulMsg: ArgsProps = React.useMemo(
    () => ({
      key: ProductMigrationStatusKeys.Success,
      closeIcon: <CloseOutlined />,
      message: (
        <Text className="text-sm">
          {translate('number_product_are_updated_successfully', {
            '[NUMBER]': status?.numberOfValidProduct || 0,
          })}
        </Text>
      ),
      className: 'migrate-message',
      getContainer: () => document.querySelector('main.ant-layout-content') || document.body,
      style: {
        border: '1px solid #B7EB8F',
        background: '#F6FFED',
        padding: '12px 16px',
      },
      duration: 5,
      bottom: 0,
      placement: 'bottomLeft',
    }),
    [status?.numberOfValidProduct, translate]
  );

  const submitUploadProductsToMigrate = React.useCallback(
    async ({ file }: { file: any }) => {
      const data = new FormData();
      data.append('File', file);
      setIsUploading(true);
      try {
        const res = await requestWithErrorLogging({
          url: '/product-service/api/products/migrate/bulk',
          method: 'post',
          data,
          timeout: TIMEOUT_UPLOAD,
        });
        const migrationId = res.data || '';
        if (migrationId) {
          setMigrationId(migrationId);
        }
      } catch (err) {
        showError([], translate('update_version_product_error'), translate('update_version_product_error_description'));
        throw err;
      } finally {
        setIsUploading(false);
      }
    },
    [showError, translate]
  );

  React.useEffect(() => {
    if (migrationId && status) {
      switch (true) {
        case status.hasError && !!status.errorReportUrl:
          notification.close(ProductMigrationStatusKeys.Processing);
          notification.error(configErrorMsg);
          setMigrationId('');
          break;

        case status.status === ProductMigrationStatus.Success && !status.hasError:
          notification.close(ProductMigrationStatusKeys.Processing);
          notification.success(configSuccessfulMsg);
          setMigrationId('');
          break;

        default:
          notification.open(configProcessingMsg);
          break;
      }
    }
  }, [configErrorMsg, configProcessingMsg, configSuccessfulMsg, migrationId, status]);

  return {
    isUploading,
    submitUploadProductsToMigrate,
  };
};

export const useReindexButton = (type: string, onFinishCountdown: () => void) => {
  const { translate } = useContext(LanguageContext);
  const { showError } = useShowError();
  const [nextReindexTime, setNextReindexTime] = useState<Date | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const key = useMemo(() => `next_reindex_${type}_${currentUser()}_time`, [type]);

  useEffect(() => {
    const nextReindexTime = Cookies.get(key);
    if (nextReindexTime) {
      setNextReindexTime(new Date(nextReindexTime));
    }
  }, [key]);

  const handleReindex = async () => {
    try {
      setIsLoading(true);
      if (type === 'products') {
        await reindexProducts();
      } else {
        await reindexCarts();
      }
      const date = new Date();
      date.setMinutes(date.getMinutes() + 5);
      setNextReindexTime(date);
      Cookies.set(key, date.toString(), { expires: date });
    } catch (err) {
      const genericErrors = getGenericErrors(err);
      showError(genericErrors, translate('refresh_error'), translate('refresh_error_description'));
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const handleFinishCountdown = useCallback(() => {
    setNextReindexTime(null);
    onFinishCountdown();
  }, [onFinishCountdown]);

  const ReindexButton = (
    <div className={classNames('flex items-center', type === 'products' && 'flex-row-reverse')}>
      <Button type="primary" onClick={handleReindex} disabled={!!nextReindexTime} loading={isLoading}>
        {!nextReindexTime ? (
          translate('refresh')
        ) : (
          <span>
            <Countdown
              value={moment(nextReindexTime).toDate().getTime()}
              className="font-medium"
              valueStyle={{ fontSize: '0.8rem' }}
              onFinish={handleFinishCountdown}
            />
          </span>
        )}
      </Button>
      {!!nextReindexTime && (
        <div className={classNames('ml-2 text-xs', type === 'products' ? 'mr-2' : 'ml-2')} style={{ color: '#FBB117' }}>
          <p>{translate(`we_are_trying_to_reload_your_${type}`)}</p>
          <p>{translate('please_be_patient_and_wait_a_few_minutes')}</p>
        </div>
      )}
    </div>
  );

  return {
    ReindexButton,
  };
};

export const useProductCode = ({ enabled, id, isCopy }: { enabled: boolean; id?: string; isCopy?: boolean }) => {
  const { data: productCode, isValidating: isLoadingProductCode } = useSWR<string>(
    enabled ? ['/product-service/api/products/new-product-code', id, isCopy] : null,
    () => getProductCode()
  );

  return {
    isLoadingProductCode,
    productCode,
  };
};

export const useFetchAllProducts = ({
  selectedProductGroupIds,
  selectedShippingGroupIds,
  selectedCatalogueIds,
  selectedPostIds,
  search,
  enabled = true,
  pageSize = DEFAULT_PAGE_SIZE,
  isFetchSingleVariantAsNormal = false,
  excludeIds,
  includeIds,
  bundleId,
  isSearchAvailableProductsForBundle,
  isIncludeBundleProducts = false,
  sortedInfo,
}: {
  selectedProductGroupIds?: string[];
  selectedShippingGroupIds?: string[];
  selectedCatalogueIds?: string[];
  selectedPostIds?: string[];
  search?: string;
  enabled?: boolean;
  pageSize?: number;
  isFetchSingleVariantAsNormal?: boolean;
  excludeIds?: string[];
  includeIds?: string[];
  bundleId?: string;
  isSearchAvailableProductsForBundle?: boolean;
  isIncludeBundleProducts?: boolean;
  sortedInfo?: SorterResult<Product>;
}) => {
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [products, setProducts] = useState<{
    [productId: string]: Product;
  }>({});
  const [variants, setVariants] = useState<{
    [productId: string]: string[];
  }>({});

  const variantIdsFlattenList = useMemo(() => flatten(Object.values(variants)), [variants]);
  const { variantData, isLoadingVariants: isLoadingVariantsByES } = useFetchVariantsByES({
    variantIds: variantIdsFlattenList,
  });

  const handleFetchProducts = useCallback(async () => {
    setIsFetching(true);
    let totalProducts = 0,
      pageNumber = 1;
    while (pageNumber === 1 || pageNumber <= Math.ceil(totalProducts / pageSize)) {
      const pagination: PaginationPayload = {
        _page: pageNumber,
        _limit: pageSize,
        ...(isEmpty(sortedInfo) || !sortedInfo?.order
          ? {
              _sort_key: 'product_created_at',
              _sort_direction: 'desc',
            }
          : {
              _sort_key: getProductSortKey(sortedInfo.field as string | number),
              _sort_direction: sortedInfo.order === 'ascend' ? 'asc' : 'desc',
            }),
      };

      const isIncludeGeneralShippingGroup = selectedShippingGroupIds?.includes(FILTER_GENERAL_PRODUCTS_OPTION_ID);
      const filterShippingGroupIds = isIncludeGeneralShippingGroup
        ? selectedShippingGroupIds?.filter((o) => o !== FILTER_GENERAL_PRODUCTS_OPTION_ID)
        : selectedShippingGroupIds;

      const filter: ProductFilter = {
        product_status: ProductStatusKeys.Active,
        ...(!selectedProductGroupIds?.length ? {} : { product_group_ids: selectedProductGroupIds }),
        ...(!isIncludeGeneralShippingGroup ? {} : { is_include_general_shipping_group: isIncludeGeneralShippingGroup }),
        ...(!filterShippingGroupIds?.length ? {} : { shipping_group_ids: filterShippingGroupIds }),
        ...(!selectedCatalogueIds?.length ? {} : { product_catalogue_ids: selectedCatalogueIds }),
        ...(!selectedPostIds?.length ? {} : { product_post_ids: selectedPostIds }),
        ...(isEmpty(search) ? {} : { _keywords: search?.toLowerCase() }),
        ...(isEmpty(excludeIds) ? {} : { exclude_product_ids: excludeIds }),
        ...(!includeIds ? {} : { include_product_ids: includeIds }),
        ...(!bundleId ? {} : { bundle_ids: [bundleId] }),
        ...(!isIncludeBundleProducts ? {} : { is_include_bundle_products: isIncludeBundleProducts }),
        ...(!isSearchAvailableProductsForBundle
          ? {}
          : { bundle_available_product: isSearchAvailableProductsForBundle }),
      };

      const { rawDataFromES, data, total } = await getProductWithES({
        pagination,
        filter,
        isFetchSingleVariantAsNormal,
      })();

      const productWithVariants = isFetchSingleVariantAsNormal
        ? rawDataFromES?.filter((product) => (product?.variants?.length || 0) > 1)
        : rawDataFromES?.filter((product) => !isEmpty(product?.variants));

      const variantIdsList: { [key: string]: string[] } =
        productWithVariants?.reduce((acc, product) => {
          return { ...acc, [product.product_id]: product?.variants?.map((o) => o?.product_id || '') || [] };
        }, {}) || {};

      setProducts((prevProducts) => ({
        ...prevProducts,
        ...keyBy(data?.data || [], 'id'),
      }));
      setVariants((prevVariants) => ({ ...prevVariants, ...variantIdsList }));
      pageNumber++;
      totalProducts = total;
    }
    setIsFetching(false);
  }, [
    pageSize,
    sortedInfo,
    selectedShippingGroupIds,
    selectedProductGroupIds,
    selectedCatalogueIds,
    selectedPostIds,
    search,
    excludeIds,
    includeIds,
    bundleId,
    isIncludeBundleProducts,
    isSearchAvailableProductsForBundle,
    isFetchSingleVariantAsNormal,
  ]);

  useEffect(() => {
    if (enabled) {
      setProducts({});
      setVariants({});
      handleFetchProducts();
    }
  }, [
    enabled,
    search,
    JSON.stringify(selectedProductGroupIds),
    JSON.stringify(selectedShippingGroupIds),
    JSON.stringify(selectedCatalogueIds),
    JSON.stringify(selectedPostIds),
    JSON.stringify(excludeIds),
    JSON.stringify(includeIds),
  ]);

  const productsData = useMemo(() => {
    if (isFetchSingleVariantAsNormal)
      return pickBy(products, (product) => !product.isMaster && !product.masterProductId);
    return products;
  }, [isFetchSingleVariantAsNormal, products]);

  const groupVariantsByMasterId = useMemo(() => {
    if (!isFetchSingleVariantAsNormal)
      return variantData?.length ? groupBy(variantData, (product) => product.masterProductId) : {};

    const productsHasSingleVariant = pickBy(products, (product) => !product.isMaster && product.masterProductId);
    const formatProduct: Dictionary<ProductVariant[]> = Object.keys(productsHasSingleVariant).reduce(
      (prev, cur) => ({
        ...prev,
        [productsHasSingleVariant[cur]?.masterProductId || '']: [productsHasSingleVariant[cur]],
      }),
      {}
    );
    if (!variantData?.length) return { ...formatProduct };
    const groupedVariants = groupBy(variantData, (product) => product.masterProductId);
    Object.keys(groupedVariants).forEach((productId) => {
      const masterProduct = products[productId];
      groupedVariants[productId] = formatVariantWithProductInfo(masterProduct, groupedVariants[productId]);
    });
    return { ...groupedVariants, ...formatProduct };
  }, [isFetchSingleVariantAsNormal, products, variantData]);

  const isLoadingProducts = useMemo(() => {
    return isFetching || isLoadingVariantsByES;
  }, [isFetching, isLoadingVariantsByES]);

  return {
    productsData: productsData,
    variantsData: groupVariantsByMasterId,
    variantIdsData: variants,
    isFetching: isLoadingProducts,
    setProducts,
    setVariants,
  };
};

export const useFetchProductCountES = ({
  refreshInterval = 0,
  pagination,
  search,
  productStatusKey,
  selectedProductGroupIds,
  selectedShippingGroupIds,
  selectedCatalogueIds,
  isSearchAvailableProductsForBundle = false,
  isIncludeBundleProducts = false,
  isRevalidateOnFocus = false,
  bundleIds = [],
  enabled = true,
  excludeIds,
  includeIds,
  capturingSessionId,
  productSessionApplyOn,
  productSessionCapturingAt,
  includeDeleted,
}: {
  refreshInterval?: number;
  search?: string;
  pagination?: TablePaginationConfig;
  productStatusKey?: ProductStatusKeys;
  selectedProductGroupIds?: string[];
  selectedShippingGroupIds?: string[];
  selectedCatalogueIds?: string[];
  isSearchAvailableProductsForBundle?: boolean;
  isIncludeBundleProducts?: boolean;
  isRevalidateOnFocus?: boolean;
  bundleIds?: string[];
  enabled?: boolean;
  excludeIds?: string[];
  includeIds?: string[];
  capturingSessionId?: string;
  productSessionApplyOn?: CapturingSessionApplyOn;
  productSessionCapturingAt?: string;
  includeDeleted?: boolean;
}) => {
  const isIncludeGeneralShippingGroup = selectedShippingGroupIds?.includes(FILTER_GENERAL_PRODUCTS_OPTION_ID);
  const filterShippingGroupIds = isIncludeGeneralShippingGroup
    ? selectedShippingGroupIds?.filter((o) => o !== FILTER_GENERAL_PRODUCTS_OPTION_ID)
    : selectedShippingGroupIds;

  const paginationObject: PaginationPayload = useMemo(() => {
    return {
      _page: pagination?.current || DEFAULT_PAGE_NUMBER,
      _limit: pagination?.pageSize || DEFAULT_PAGE_SIZE,
    };
  }, [pagination]);

  const filterObject: ProductFilter = useMemo(() => {
    return {
      ...(!productStatusKey ? {} : { product_status: productStatusKey }),
      ...(!isIncludeGeneralShippingGroup ? {} : { is_include_general_shipping_group: isIncludeGeneralShippingGroup }),
      ...(!selectedProductGroupIds?.length ? {} : { product_group_ids: selectedProductGroupIds }),
      ...(!filterShippingGroupIds?.length ? {} : { shipping_group_ids: filterShippingGroupIds }),
      ...(!selectedCatalogueIds?.length ? {} : { product_catalogue_ids: selectedCatalogueIds }),
      ...(isEmpty(search) ? {} : { _keywords: search?.toLowerCase() }),
      ...(!isSearchAvailableProductsForBundle ? {} : { bundle_available_product: isSearchAvailableProductsForBundle }),
      ...(!isIncludeBundleProducts ? {} : { is_include_bundle_products: isIncludeBundleProducts }),
      ...(isEmpty(bundleIds) ? {} : { bundle_ids: bundleIds }),
      ...(isEmpty(excludeIds) ? {} : { exclude_product_ids: excludeIds }),
      ...(!includeIds ? {} : { include_product_ids: includeIds }),
      ...(isEmpty(capturingSessionId) ? {} : { product_capturing_session_id: capturingSessionId }),
      ...(productSessionApplyOn === undefined ? {} : { product_capturing_session_apply_on: productSessionApplyOn }),
      ...(!productSessionCapturingAt ? {} : { product_capturing_session_capturing_at: productSessionCapturingAt }),
      ...(!includeDeleted ? {} : { include_deleted: includeDeleted }),
    };
  }, [
    productStatusKey,
    isIncludeGeneralShippingGroup,
    selectedProductGroupIds,
    filterShippingGroupIds,
    selectedCatalogueIds,
    search,
    isSearchAvailableProductsForBundle,
    isIncludeBundleProducts,
    bundleIds,
    excludeIds,
    includeIds,
    productSessionApplyOn,
  ]);

  const currentCountProductsQueryString = useMemo(() => {
    if (!enabled) return null;
    return `/products/count${requestParamsFromObject({ ...paginationObject, ...filterObject })}`;
  }, [enabled, paginationObject, filterObject]);

  const {
    data: response,
    isValidating,
    revalidate,
  } = useSWR<ProductResponseWithES>(
    currentCountProductsQueryString,
    countProduct({ pagination: paginationObject, filter: filterObject }),
    {
      ...(refreshInterval ? { refreshInterval: refreshInterval } : {}),
      revalidateOnFocus: isRevalidateOnFocus,
    }
  );

  return {
    productAndVariantCount: response?.productAndVariantCount || 0,
    isLoadingProductCount: isValidating,
    revalidateCount: revalidate,
  };
};

export const useFetchAllProductsV2 = ({
  selectedProductGroupIds,
  selectedShippingGroupIds,
  selectedCatalogueIds,
  selectedPostIds,
  search,
  enabled = true,
  pageSize = DEFAULT_PAGE_SIZE,
  isFetchSingleVariantAsNormal = false,
  excludeIds,
  includeIds,
  bundleId,
  isSearchAvailableProductsForBundle,
  isIncludeBundleProducts = false,
  sortedInfo,
  limitProducts = 0,
}: {
  selectedProductGroupIds?: string[];
  selectedShippingGroupIds?: string[];
  selectedCatalogueIds?: string[];
  selectedPostIds?: string[];
  search?: string;
  enabled?: boolean;
  pageSize?: number;
  isFetchSingleVariantAsNormal?: boolean;
  excludeIds?: string[];
  includeIds?: string[];
  bundleId?: string;
  isSearchAvailableProductsForBundle?: boolean;
  isIncludeBundleProducts?: boolean;
  sortedInfo?: SorterResult<Product>;
  limitProducts?: number;
}) => {
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [products, setProducts] = useState<string[]>([]);
  const [variants, setVariants] = useState<string[]>([]);

  const { data: productData, isValidating: isLoadingProduct } = useFetchProductsByIds(products);
  const { variantData, isLoadingVariants: isLoadingVariantsByES } = useFetchVariantsByES({
    variantIds: variants,
  });

  const handleFetchProducts = useCallback(async () => {
    setIsFetching(true);
    let totalProducts = 0,
      pageNumber = 1,
      countProduct = 0,
      productsData: string[] = [],
      variantsData: string[] = [];
    while (
      (!limitProducts && (pageNumber === 1 || pageNumber <= Math.ceil(totalProducts / pageSize))) ||
      (limitProducts &&
        countProduct < limitProducts &&
        (pageNumber === 1 || pageNumber <= Math.ceil(totalProducts / pageSize)))
    ) {
      const pagination: PaginationPayload = {
        _page: pageNumber,
        _limit: pageSize,
        ...(isEmpty(sortedInfo) || !sortedInfo?.order
          ? {
              _sort_key: 'product_created_at',
              _sort_direction: 'desc',
            }
          : {
              _sort_key: getProductSortKey(sortedInfo.field as string | number),
              _sort_direction: sortedInfo.order === 'ascend' ? 'asc' : 'desc',
            }),
      };

      const isIncludeGeneralShippingGroup = selectedShippingGroupIds?.includes(FILTER_GENERAL_PRODUCTS_OPTION_ID);
      const filterShippingGroupIds = isIncludeGeneralShippingGroup
        ? selectedShippingGroupIds?.filter((o) => o !== FILTER_GENERAL_PRODUCTS_OPTION_ID)
        : selectedShippingGroupIds;

      const filter: ProductFilter = {
        product_status: ProductStatusKeys.Active,
        ...(!selectedProductGroupIds?.length ? {} : { product_group_ids: selectedProductGroupIds }),
        ...(!isIncludeGeneralShippingGroup ? {} : { is_include_general_shipping_group: isIncludeGeneralShippingGroup }),
        ...(!filterShippingGroupIds?.length ? {} : { shipping_group_ids: filterShippingGroupIds }),
        ...(!selectedCatalogueIds?.length ? {} : { product_catalogue_ids: selectedCatalogueIds }),
        ...(!selectedPostIds?.length ? {} : { product_post_ids: selectedPostIds }),
        ...(isEmpty(search) ? {} : { _keywords: search?.toLowerCase() }),
        ...(isEmpty(excludeIds) ? {} : { exclude_product_ids: excludeIds }),
        ...(!includeIds ? {} : { include_product_ids: includeIds }),
        ...(!bundleId ? {} : { bundle_ids: [bundleId] }),
        ...(!isIncludeBundleProducts ? {} : { is_include_bundle_products: isIncludeBundleProducts }),
        ...(!isSearchAvailableProductsForBundle
          ? {}
          : { bundle_available_product: isSearchAvailableProductsForBundle }),
      };

      const { data, total } = await getProductWithESV2({
        pagination,
        filter,
      })();

      data.map((product) => {
        if (limitProducts && countProduct >= limitProducts) return;
        if (!product.is_master) {
          countProduct++;
          productsData = [...productsData, product.product_id];
        } else {
          const variantCount = product.variants?.length || 0;
          const temptCount = variantCount + countProduct;
          const limit = limitProducts && temptCount > limitProducts ? limitProducts - countProduct : variantCount;
          const availableVariants = take(product.variants, limit);
          const ids = availableVariants.map((item) => item.product_id);
          countProduct = Math.min(temptCount, limitProducts);
          if (isFetchSingleVariantAsNormal && variantCount === 1) {
            productsData = [...productsData, product.variants?.[0].product_id || ''];
          } else {
            productsData = [...productsData, product.product_id];
            variantsData = [...variantsData, ...ids];
          }
        }
      });
      pageNumber++;
      totalProducts = total;
    }
    setProducts(productsData);
    setVariants(variantsData);
    setIsFetching(false);
  }, [
    pageSize,
    sortedInfo,
    selectedShippingGroupIds,
    selectedProductGroupIds,
    selectedCatalogueIds,
    selectedPostIds,
    search,
    excludeIds,
    includeIds,
    bundleId,
    isIncludeBundleProducts,
    isSearchAvailableProductsForBundle,
    isFetchSingleVariantAsNormal,
    limitProducts,
  ]);

  useEffect(() => {
    if (enabled) {
      setProducts([]);
      setVariants([]);
      handleFetchProducts();
    }
  }, [
    enabled,
    search,
    JSON.stringify(selectedProductGroupIds),
    JSON.stringify(selectedShippingGroupIds),
    JSON.stringify(selectedCatalogueIds),
    JSON.stringify(selectedPostIds),
    JSON.stringify(excludeIds),
    JSON.stringify(includeIds),
  ]);

  const productsData = useMemo(() => keyBy(productData, 'id'), [productData]);

  const groupVariantsByMasterId = useMemo(() => {
    if (!variantData?.length) return {};
    return groupBy(variantData, (product) => product.masterProductId);
  }, [variantData]);

  const isLoadingProducts = useMemo(() => {
    return isFetching || isLoadingVariantsByES || isLoadingProduct;
  }, [isFetching, isLoadingProduct, isLoadingVariantsByES]);

  return {
    productsData,
    variantsData: groupVariantsByMasterId,
    variantIdsData: variants,
    isFetching: isLoadingProducts,
    setProducts,
    setVariants,
  };
};

export const useFetchProductsByIds = (ids: string[]) => {
  const { data, isValidating } = useSWR<{
    data: Product[];
  }>(!isEmpty(ids) ? ['/product-service/api/products/search/ids', ...ids] : null, fetchProductByIds(ids));

  return {
    data: data?.data,
    isValidating,
  };
};

export const useFetchAllProductWithoutGroupByMaster = ({
  selectedProductGroupIds,
  selectedShippingGroupIds,
  selectedCatalogueIds,
  selectedPostIds,
  search,
  enabled = true,
  pageSize = DEFAULT_PAGE_SIZE,
  excludeIds,
  includeIds,
  sortedInfo,
  limitProducts = 0,
  checkValidProductStock = false,
}: {
  selectedProductGroupIds?: string[];
  selectedShippingGroupIds?: string[];
  selectedCatalogueIds?: string[];
  selectedPostIds?: string[];
  search?: string;
  enabled?: boolean;
  pageSize?: number;
  excludeIds?: string[];
  includeIds?: string[];
  sortedInfo?: SorterResult<Product>;
  limitProducts?: number;
  checkValidProductStock?: boolean;
}) => {
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [products, setProducts] = useState<string[]>([]);
  const [variants, setVariants] = useState<string[]>([]);

  const { data: productData, isValidating: isLoadingProduct } = useFetchProductsByIds(products);
  const { variantData, isLoadingVariants: isLoadingVariantsByES } = useFetchVariantsByES({
    variantIds: variants,
  });

  const handleFetchProducts = useCallback(async () => {
    setIsFetching(true);
    let totalProducts = 0,
      pageNumber = 1,
      countProduct = 0,
      productsData: string[] = [],
      variantsData: string[] = [];
    while (
      (!limitProducts && (pageNumber === 1 || pageNumber <= Math.ceil(totalProducts / pageSize))) ||
      (limitProducts &&
        countProduct < limitProducts &&
        (pageNumber === 1 || pageNumber <= Math.ceil(totalProducts / pageSize)))
    ) {
      const pagination: PaginationPayload = {
        _page: pageNumber,
        _limit: pageSize,
        ...(isEmpty(sortedInfo) || !sortedInfo?.order
          ? {
              _sort_key: 'product_created_at',
              _sort_direction: 'desc',
            }
          : {
              _sort_key: getProductSortKey(sortedInfo.field as string | number),
              _sort_direction: sortedInfo.order === 'ascend' ? 'asc' : 'desc',
            }),
      };

      const isIncludeGeneralShippingGroup = selectedShippingGroupIds?.includes(FILTER_GENERAL_PRODUCTS_OPTION_ID);
      const filterShippingGroupIds = isIncludeGeneralShippingGroup
        ? selectedShippingGroupIds?.filter((o) => o !== FILTER_GENERAL_PRODUCTS_OPTION_ID)
        : selectedShippingGroupIds;

      const filter: ProductFilter = {
        product_status: ProductStatusKeys.Active,
        ...(!selectedProductGroupIds?.length ? {} : { product_group_ids: selectedProductGroupIds }),
        ...(!isIncludeGeneralShippingGroup ? {} : { is_include_general_shipping_group: isIncludeGeneralShippingGroup }),
        ...(!filterShippingGroupIds?.length ? {} : { shipping_group_ids: filterShippingGroupIds }),
        ...(!selectedCatalogueIds?.length ? {} : { product_catalogue_ids: selectedCatalogueIds }),
        ...(!selectedPostIds?.length ? {} : { product_post_ids: selectedPostIds }),
        ...(isEmpty(search) ? {} : { _keywords: search?.toLowerCase() }),
        ...(isEmpty(excludeIds) ? {} : { exclude_product_ids: excludeIds }),
        ...(!includeIds ? {} : { include_product_ids: includeIds }),
      };

      const { data, total } = await getProductWithESV2({
        pagination,
        filter,
      })();

      data.map((product) => {
        if (limitProducts && countProduct >= limitProducts) return;
        if (!product.is_master) {
          countProduct++;
        } else {
          const variantCount = product.variants?.length || 0;
          const temptCount = variantCount + countProduct;
          const limit = limitProducts && temptCount > limitProducts ? limitProducts - countProduct : variantCount;
          const availableVariants = take(product.variants, limit);
          const ids = availableVariants.map((item) => item.product_id);
          variantsData = [...variantsData, ...ids];
          countProduct = Math.min(temptCount, limitProducts);
        }
        productsData = [...productsData, product.product_id];
      });
      pageNumber++;
      totalProducts = total;
    }
    setProducts(productsData);
    setVariants(variantsData);
    setIsFetching(false);
  }, [
    pageSize,
    sortedInfo,
    selectedShippingGroupIds,
    selectedProductGroupIds,
    selectedCatalogueIds,
    selectedPostIds,
    search,
    excludeIds,
    includeIds,
    limitProducts,
  ]);

  useEffect(() => {
    if (enabled) {
      setProducts([]);
      setVariants([]);
      handleFetchProducts();
    }
  }, [
    enabled,
    search,
    JSON.stringify(selectedProductGroupIds),
    JSON.stringify(selectedShippingGroupIds),
    JSON.stringify(selectedCatalogueIds),
    JSON.stringify(selectedPostIds),
    JSON.stringify(excludeIds),
    JSON.stringify(includeIds),
  ]);

  const formattedProductsData = useMemo(() => {
    let normalProducts = productData?.filter((product) => !product.isMaster) || [];
    let variants = variantData || [];

    if (checkValidProductStock) {
      normalProducts = normalProducts.filter((product) => (product.productStock || 0) > 0);
      variants = variants.filter((variant) => (variant.productStock || 0) > 0);
    }

    return [...normalProducts, ...variants];
  }, [productData, variantData]);

  const isLoadingProducts = useMemo(() => {
    return isFetching || isLoadingVariantsByES || isLoadingProduct;
  }, [isFetching, isLoadingProduct, isLoadingVariantsByES]);

  return {
    productsData: formattedProductsData,
    variantIdsData: variants,
    isFetching: isLoadingProducts,
  };
};

export const useFetchProductDataByESInfinite = ({
  refreshInterval = 0,
  pagination,
  search,
  sortedInfo,
  productStatusKey,
  selectedProductGroupIds,
  selectedShippingGroupIds,
  selectedCatalogueIds,
  isFetchSingleVariantAsNormal = false,
  isSearchAvailableProductsForBundle = false,
  isIncludeBundleProducts = false,
  isRevalidateOnFocus = false,
  bundleIds = [],
  enabled = true,
  excludeIds,
  includeIds,
  isFetchFlattenVariants = false,
}: {
  refreshInterval?: number;
  search?: string;
  pagination?: TablePaginationConfig;
  sortedInfo?: SorterResult<Product>;
  productStatusKey?: ProductStatusKeys;
  selectedProductGroupIds?: string[];
  selectedShippingGroupIds?: string[];
  selectedCatalogueIds?: string[];
  isFetchSingleVariantAsNormal?: boolean;
  isSearchAvailableProductsForBundle?: boolean;
  isIncludeBundleProducts?: boolean;
  isRevalidateOnFocus?: boolean;
  bundleIds?: string[];
  enabled?: boolean;
  excludeIds?: string[];
  includeIds?: string[];
  isFetchFlattenVariants?: boolean;
}) => {
  const isIncludeGeneralShippingGroup = selectedShippingGroupIds?.includes(FILTER_GENERAL_PRODUCTS_OPTION_ID);
  const filterShippingGroupIds = isIncludeGeneralShippingGroup
    ? selectedShippingGroupIds?.filter((o) => o !== FILTER_GENERAL_PRODUCTS_OPTION_ID)
    : selectedShippingGroupIds;

  const pageSize = useMemo(() => pagination?.pageSize || INFINITE_DEFAULT_PAGE_SIZE, [pagination?.pageSize]);

  const paginationObject: PaginationPayload = useMemo(() => {
    return {
      _limit: pageSize,
      ...(isEmpty(sortedInfo) || !sortedInfo?.order
        ? {
            _sort_key: 'product_created_at',
            _sort_direction: 'desc',
          }
        : {
            _sort_key: getProductSortKey(sortedInfo.field as string | number),
            _sort_direction: sortedInfo.order === 'ascend' ? 'asc' : 'desc',
          }),
    };
  }, [sortedInfo, pageSize]);

  const filterObject: ProductFilter = useMemo(() => {
    return {
      ...(!productStatusKey ? {} : { product_status: productStatusKey }),
      ...(!isIncludeGeneralShippingGroup ? {} : { is_include_general_shipping_group: isIncludeGeneralShippingGroup }),
      ...(!selectedProductGroupIds?.length ? {} : { product_group_ids: selectedProductGroupIds }),
      ...(!filterShippingGroupIds?.length ? {} : { shipping_group_ids: filterShippingGroupIds }),
      ...(!selectedCatalogueIds?.length ? {} : { product_catalogue_ids: selectedCatalogueIds }),
      ...(isEmpty(search) ? {} : { _keywords: search?.toLowerCase() }),
      ...(!isSearchAvailableProductsForBundle ? {} : { bundle_available_product: isSearchAvailableProductsForBundle }),
      ...(!isIncludeBundleProducts ? {} : { is_include_bundle_products: isIncludeBundleProducts }),
      ...(isEmpty(bundleIds) ? {} : { bundle_ids: bundleIds }),
      ...(isEmpty(excludeIds) ? {} : { exclude_product_ids: excludeIds }),
      ...(!includeIds ? {} : { include_product_ids: includeIds }),
    };
  }, [
    productStatusKey,
    isIncludeGeneralShippingGroup,
    selectedProductGroupIds,
    filterShippingGroupIds,
    selectedCatalogueIds,
    search,
    isSearchAvailableProductsForBundle,
    isIncludeBundleProducts,
    bundleIds,
    excludeIds,
    includeIds,
  ]);

  const getProductESKey = (pageIndex: number, previousPageData: ProductResponseWithES | null) => {
    if (!enabled || (previousPageData?.data?.data && previousPageData.data.data.length < pageSize)) return null;

    const baseKey = isFetchFlattenVariants ? '/products/flatten' : '/products';

    return [
      `${baseKey}${requestParamsFromObject({ ...paginationObject })}&_page=${pageIndex + 1}`,
      `${baseKey}${requestParamsFromObject({ ...filterObject })}`,
    ];
  };

  const {
    data,
    isValidating: isLoadingProducts,
    revalidate,
    size,
    setSize,
  } = useSWRInfinite<ProductResponseWithES>(
    getProductESKey,
    getProductWithESInfinite({ pagination: paginationObject, filter: filterObject, isFetchSingleVariantAsNormal }),
    {
      ...(refreshInterval ? { refreshInterval: refreshInterval } : {}),
      revalidateOnFocus: isRevalidateOnFocus,
    }
  );

  const isReachingEnd = useMemo(() => {
    const isEmpty = !data?.[0]?.data?.data.length;
    const isLastPage = data && (data[data.length - 1].data?.data?.length || 0) < pageSize;
    return !!isEmpty || !!isLastPage;
  }, [data, pageSize]);

  const formattedData = useMemo(() => flatten(data?.map((list) => list.data?.data || [])), [data]);
  const formattedRawDataFromES = useMemo(() => flatten(data?.map((list) => list.rawDataFromES || [])), [data]);

  return {
    data: formattedData,
    rawDataFromES: formattedRawDataFromES,
    size,
    setSize,
    isLoadingProducts,
    revalidate,
    isReachingEnd,
  };
};

export const useFlowFetchProductsByESInfinite = ({
  refreshInterval,
  pagination,
  sortedInfo,
  search,
  productStatusKey,
  selectedProductGroupIds,
  selectedShippingGroupIds,
  selectedCatalogueIds,
  isFetchSingleVariantAsNormal = false,
  isSearchAvailableProductsForBundle = false,
  isIncludeBundleProducts = false,
  isRevalidateOnFocus = true,
  bundleIds = [],
  enabled = true,
  excludeIds,
  includeIds,
}: FlowFetchProductsByESProps) => {
  const { data, rawDataFromES, isLoadingProducts, setSize, size, revalidate } = useFetchProductDataByESInfinite({
    refreshInterval,
    pagination,
    sortedInfo,
    search,
    productStatusKey,
    selectedProductGroupIds,
    selectedShippingGroupIds,
    selectedCatalogueIds,
    isFetchSingleVariantAsNormal,
    isSearchAvailableProductsForBundle,
    isIncludeBundleProducts,
    isRevalidateOnFocus,
    bundleIds,
    enabled,
    excludeIds,
    includeIds,
  });

  const variantIdsList: { [key: string]: string[] } = useMemo(() => {
    const productWithVariants = isFetchSingleVariantAsNormal
      ? rawDataFromES?.filter((product) => (product?.variants?.length || 0) > 1)
      : rawDataFromES?.filter((product) => !isEmpty(product?.variants));
    return (
      productWithVariants?.reduce((acc, product) => {
        return { ...acc, [product.product_id]: product?.variants?.map((o) => o?.product_id || '') || [] };
      }, {}) || {}
    );
  }, [isFetchSingleVariantAsNormal, rawDataFromES]);

  return {
    productData: data,
    isFetchingByES: isLoadingProducts,
    variantIdsList,
    setSize,
    size,
    revalidate,
  };
};

export const useMassCreateQuickProduct = ({
  onSuccess,
  onError,
}: {
  onSuccess: (res: string[]) => void;
  onError?: (error: any) => void;
}) => {
  const { showError } = useShowError();
  const { translate } = useContext(LanguageContext);
  const { analyticsTrack } = useSegement();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleMassCreate = useCallback(
    async (products: Product[]) => {
      const commonTrackingEventData = generateCommonTrackingData();
      setIsLoading(true);
      try {
        const res = await massCreateQuickProduct(products);
        const productIds = res.productIds;
        message.success(translate('mass_create_products_successfully'));
        if (products.length > 1) {
          analyticsTrack('Product Created', {
            product_id: productIds,
            mass_create: true,
            with_variations: false,
            num_products_created: productIds.length,
            type: location.pathname.includes('product/quick-create') ? 'no variation' : 'with variation',
            ...commonTrackingEventData,
          });
        } else {
          analyticsTrack('Product Created', {
            product_id: productIds,
            mass_create: false,
            product_category: !!products[0].categoryId,
            with_variations: false,
            image: !isEmpty(products[0].productImages),
            description: !!products[0].description,
            weight: !!products[0].weight,
            dimensions: !!products[0].width || !!products[0].height || !!products[0].length,
            cost_price: !!products[0].costPrice,
            num_products_created: 1,
            type: location.pathname.includes('product/quick-create') ? 'no variation' : 'with variation',
            ...commonTrackingEventData,
          });
        }
        onSuccess(productIds);
        return productIds;
      } catch (err) {
        const genericErrors = getErrorsArray(err);
        showError(genericErrors, translate('mass_create_products_error'), translate('error_occurred_try_again'));
        onError?.(err);
      } finally {
        setIsLoading(false);
      }
    },
    [analyticsTrack, onError, onSuccess, showError, translate, location]
  );

  return { handleMassCreate, isCreating: isLoading };
};

export const useWaitAndGetProducts = (ids: string[], isFetchFlattenVariants?: boolean) => {
  const [isLoading, setIsLoading] = useState(false);
  const { total, variantIdsList, productData, isFetchingByES, revalidate } = useFlowFetchProductsByES({
    includeIds: ids,
    enabled: !isEmpty(ids),
    pagination: {
      pageSize: ids.length,
    },
    isFetchFlattenVariants: isFetchFlattenVariants,
  });

  const variantIdsFlattenList = useMemo(() => flatten(Object.values(variantIdsList)), [variantIdsList]);
  const { variantData, isLoadingVariants: isLoadingVariantsByES } = useFetchVariantsByES({
    variantIds: variantIdsFlattenList,
  });

  const groupVariantsByMasterId = useMemo(
    () => (variantData?.length ? groupBy(variantData, (product) => product.masterProductId) : {}),
    [variantData]
  );

  useEffect(() => {
    if (!isEmpty(ids)) return setIsLoading(true);
  }, [ids]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    const hasData = !!total && !isEmpty(productData?.data) && productData?.data.length === ids.length;
    if (!hasData) {
      intervalId = setInterval(() => revalidate(), 500);
    } else {
      setIsLoading(false);
      clearInterval();
    }

    return () => clearInterval(intervalId);
  }, [ids.length, productData?.data, revalidate, total]);

  return {
    isFetching: isFetchingByES || isLoadingVariantsByES || isLoading,
    products: productData?.data,
    variants: groupVariantsByMasterId,
  };
};

export const useUpdateProductImage = ({
  onSuccess,
}: {
  onSuccess: (productId?: string, trackingEventData?: any) => void;
}) => {
  const { showError } = useShowError();
  const { translate } = useContext(LanguageContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleUpdateImage = useCallback(
    async ({ productId, productImage }: { productId: string; productImage: ProductImage }) => {
      setIsLoading(true);
      const commonTrackingEventData = generateCommonTrackingData();
      try {
        const image = !isEmpty(productImage) ? productImage : null;
        await updateProductImage(productId, image);
        message.success(translate('update_product_image_successfully'));
        onSuccess?.(productId, commonTrackingEventData);
      } catch (err) {
        const genericErrors = getErrorsArray(err);
        showError(genericErrors, translate('update_product_image_error'), translate('error_occurred_try_again'));
      } finally {
        setIsLoading(false);
      }
    },
    [onSuccess, showError, translate]
  );

  return { handleUpdateImage, isUpdating: isLoading };
};

export const useFlowFetchProductsInLiveStream = ({
  refreshInterval,
  pagination,
  sortedInfo,
  search,
  productStatusKey,
  isFetchSingleVariantAsNormal = false,
  isRevalidateOnFocus = true,
  enabled = true,
  excludeIds,
  includeIds,
  capturingSessionId,
  includeDeleted,
  sessionType,
  includeProductWithoutPerformance,
}: FlowFetchProductsByESProps) => {
  const paginationObject: PaginationPayload = useMemo(() => {
    return {
      _limit: pagination?.pageSize || DEFAULT_PAGE_SIZE,
      ...(isEmpty(sortedInfo) || !sortedInfo?.order
        ? {
            _sort_key: 'product_created_at',
            _sort_direction: 'desc',
          }
        : {
            _sort_key: getProductSortKey(sortedInfo.field as string | number),
            _sort_direction: sortedInfo.order === 'ascend' ? 'asc' : 'desc',
          }),
    };
  }, [sortedInfo]);

  const filterObject: ProductFilter = useMemo(() => {
    return {
      ...(!productStatusKey ? {} : { product_status: productStatusKey }),
      ...(isEmpty(search) ? {} : { _keywords: search?.toLowerCase() }),
      ...(isEmpty(excludeIds) ? {} : { exclude_product_ids: excludeIds }),
      ...(!includeIds ? {} : { include_product_ids: includeIds }),
      ...(isEmpty(capturingSessionId) ? {} : { product_capturing_session_id: capturingSessionId }),
      ...(includeDeleted === undefined ? {} : { include_deleted: includeDeleted }),
      ...(!includeProductWithoutPerformance
        ? {}
        : { include_product_without_performance: includeProductWithoutPerformance }),
    };
  }, [productStatusKey, search, excludeIds, includeIds]);

  const getProductESKey = (pageIndex: number, previousPageData: ProductResponseWithES | null) => {
    if (!enabled || (previousPageData?.data?.data && !previousPageData.data.data.length)) return null;

    return `/products${requestParamsFromObject({ ...paginationObject, ...filterObject })}&_page=${pageIndex + 1}`;
  };

  const {
    data,
    isValidating: isLoadingProducts,
    revalidate,
    size,
    setSize,
    mutate: mutateProducts,
  } = useSWRInfinite<ProductResponseWithES>(
    getProductESKey,
    getProductInLiveStream({
      pagination: paginationObject,
      filter: filterObject,
      isFetchSingleVariantAsNormal,
      capturingSessionId,
      sessionType,
    }),
    {
      ...(refreshInterval ? { refreshInterval: refreshInterval } : {}),
      revalidateOnFocus: isRevalidateOnFocus,
      revalidateAll: true,
    }
  );
  const formattedData = useMemo(() => flatten(data?.map((list) => list.data?.data || [])), [data]);
  const formattedRawDataFromES = useMemo(() => flatten(data?.map((list) => list.rawDataFromES || [])), [data]);

  const variantIdsList: { [key: string]: string[] } = useMemo(() => {
    const productWithVariants = isFetchSingleVariantAsNormal
      ? formattedRawDataFromES?.filter((product) => (product?.variants?.length || 0) > 1)
      : formattedRawDataFromES?.filter((product) => !isEmpty(product?.variants));
    return (
      productWithVariants?.reduce((acc, product) => {
        return { ...acc, [product.product_id]: product?.variants?.map((o) => o?.product_id || '') || [] };
      }, {}) || {}
    );
  }, [isFetchSingleVariantAsNormal, formattedRawDataFromES]);

  return {
    productData: formattedData,
    variantIdsList,
    size,
    setSize,
    isFetchingByES: isLoadingProducts,
    revalidate,
    mutateProducts,
  };
};

export const useFetchVariantInLiveStream = ({
  refreshInterval = 0,
  variantIds,
  capturingSessionId,
  sessionType,
}: {
  refreshInterval?: number;
  variantIds: string[];
  capturingSessionId: string;
  sessionType: 'after' | 'during';
}) => {
  const currentVariantQueryString: string = useMemo(() => {
    return `/products/search/ids/capturing-sessions/during${variantIds}`;
  }, [variantIds]);

  const {
    data: variantData,
    isValidating: isLoadingVariants,
    revalidate: revalidateVariants,
    mutate: mutateVariants,
  } = useSWR<{
    data: ProductVariant[];
  }>(
    !isEmpty(variantIds) ? currentVariantQueryString : null,
    sessionType === 'during'
      ? fetchProductDuringLiveStreamById(variantIds, capturingSessionId)
      : fetchProductAfterLiveStreamById(variantIds, capturingSessionId),
    {
      ...(refreshInterval ? { refreshInterval: refreshInterval } : {}),
      revalidateOnFocus: false,
    }
  );

  return {
    variantData: variantData?.data,
    isLoadingVariants,
    revalidateVariants,
    mutateVariants,
  };
};

export const useFetchInfiniteProductESByCursor = ({
  refreshInterval = 0,
  search,
  sortedInfo,
  productStatusKey,
  selectedProductGroupIds,
  selectedShippingGroupIds,
  selectedCatalogueIds,
  isFetchSingleVariantAsNormal = false,
  isSearchAvailableProductsForBundle = false,
  isIncludeBundleProducts = false,
  isRevalidateOnFocus = false,
  bundleIds = [],
  enabled = true,
  excludeIds,
  includeIds,
  isRevalidateAll = false,
  includeOutOfStock = true,
  priceStart,
}: {
  refreshInterval?: number;
  search?: string;
  sortedInfo?: SorterResult<Product>;
  productStatusKey?: ProductStatusKeys;
  selectedProductGroupIds?: string[];
  selectedShippingGroupIds?: string[];
  selectedCatalogueIds?: string[];
  isFetchSingleVariantAsNormal?: boolean;
  isSearchAvailableProductsForBundle?: boolean;
  isIncludeBundleProducts?: boolean;
  isRevalidateOnFocus?: boolean;
  bundleIds?: string[];
  enabled?: boolean;
  excludeIds?: string[];
  includeIds?: string[];
  isRevalidateAll?: boolean;
  includeOutOfStock?: boolean;
  priceStart?: number;
}) => {
  const isIncludeGeneralShippingGroup = selectedShippingGroupIds?.includes(FILTER_GENERAL_PRODUCTS_OPTION_ID);
  const filterShippingGroupIds = isIncludeGeneralShippingGroup
    ? selectedShippingGroupIds?.filter((o) => o !== FILTER_GENERAL_PRODUCTS_OPTION_ID)
    : selectedShippingGroupIds;

  const paginationObject: PaginationPayload = useMemo(() => {
    return {
      _limit: INFINITE_DEFAULT_PAGE_SIZE,
      ...(isEmpty(sortedInfo) || !sortedInfo?.order
        ? {
            _sort_key: 'product_created_at',
            _sort_direction: 'desc',
          }
        : {
            _sort_key: getProductSortKey(sortedInfo.field as string | number),
            _sort_direction: sortedInfo.order === 'ascend' ? 'asc' : 'desc',
          }),
    };
  }, [sortedInfo]);

  const filterObject: ProductFilter = useMemo(() => {
    return {
      ...(!productStatusKey ? {} : { product_status: productStatusKey }),
      ...(!isIncludeGeneralShippingGroup ? {} : { is_include_general_shipping_group: isIncludeGeneralShippingGroup }),
      ...(!selectedProductGroupIds?.length ? {} : { product_group_ids: selectedProductGroupIds }),
      ...(!filterShippingGroupIds?.length ? {} : { shipping_group_ids: filterShippingGroupIds }),
      ...(!selectedCatalogueIds?.length ? {} : { product_catalogue_ids: selectedCatalogueIds }),
      ...(isEmpty(search) ? {} : { _keywords: search?.toLowerCase() }),
      ...(!isSearchAvailableProductsForBundle ? {} : { bundle_available_product: isSearchAvailableProductsForBundle }),
      ...(!isIncludeBundleProducts ? {} : { is_include_bundle_products: isIncludeBundleProducts }),
      ...(isEmpty(bundleIds) ? {} : { bundle_ids: bundleIds }),
      ...(isEmpty(excludeIds) ? {} : { exclude_product_ids: excludeIds }),
      ...(!includeIds ? {} : { include_product_ids: includeIds }),
      ...(includeOutOfStock ? {} : { include_out_of_stock: includeOutOfStock }),
      ...(typeof priceStart === 'number' ? { price_start: priceStart } : {}),
    };
  }, [
    productStatusKey,
    isIncludeGeneralShippingGroup,
    selectedProductGroupIds,
    filterShippingGroupIds,
    selectedCatalogueIds,
    search,
    isSearchAvailableProductsForBundle,
    isIncludeBundleProducts,
    bundleIds,
    excludeIds,
    includeIds,
    includeOutOfStock,
    priceStart,
  ]);

  const getProductESKey = (pageIndex: number, previousPageData: ProductResponseWithES | null) => {
    if (!enabled || (previousPageData && !previousPageData.searchAfter)) return null;

    if (pageIndex === 0) return [`/products${requestParamsFromObject({ ...paginationObject, ...filterObject })}`];

    return [
      `/products${requestParamsFromObject({ ...paginationObject, ...filterObject })}`,
      ...(previousPageData?.searchAfter || []),
    ];
  };

  const {
    data,
    isValidating: isLoadingProducts,
    revalidate,
    size,
    setSize,
  } = useSWRInfinite<ProductResponseWithES>(
    getProductESKey,
    getProductWithESInfiniteByCursor({
      pagination: paginationObject,
      filter: filterObject,
      isFetchSingleVariantAsNormal,
    }),
    {
      ...(refreshInterval ? { refreshInterval: refreshInterval } : {}),
      revalidateOnFocus: isRevalidateOnFocus,
      revalidateAll: isRevalidateAll,
    }
  );

  const formattedData = useMemo(() => flatten(data?.map((list) => list.data?.data || [])), [data]);
  const formattedRawDataFromES = useMemo(() => flatten(data?.map((list) => list.rawDataFromES || [])), [data]);

  const variantIdsList: { [key: string]: string[] } = useMemo(() => {
    const productWithVariants = isFetchSingleVariantAsNormal
      ? formattedRawDataFromES?.filter((product) => (product?.variants?.length || 0) > 1)
      : formattedRawDataFromES?.filter((product) => !isEmpty(product?.variants));
    return (
      productWithVariants?.reduce((acc, product) => {
        return { ...acc, [product.product_id]: product?.variants?.map((o) => o?.product_id || '') || [] };
      }, {}) || {}
    );
  }, [isFetchSingleVariantAsNormal, formattedRawDataFromES]);

  return {
    productData: formattedData,
    isFetchingByES: isLoadingProducts,
    variantIdsList,
    setSize,
    size,
    revalidate,
  };
};
